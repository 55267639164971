import React, { Component } from "react";
import "antd/dist/antd.css";
import { Row, Col, Upload, Image, Button, Space, Divider, message } from "antd";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import server, { endpoints } from "../utils/server";
import MassMailButton from "../components/massMailButton";
import ScholarshipCard from "../components/scholarshipCard";

class Events extends Component {
  state = {
    edit: false,
    imageUrl: "",
    fileContents: "",
    fileName: "",
    loadingEventPhoto: false,
    fileList: [],
  };

  getEvents = async () => {
    const params = { id_cycle: this.props.idCycle };
    const res = await server(
      endpoints.getEvents.route,
      endpoints.getEvents.method,
      params
    );
    const imageUrl = res.data.eventsInCycleUrl;
    const edit = res.data.user_can_edit;
    this.setState({ imageUrl, edit });
  };

  postEvents = async () => {
    const { idCycle } = this.props;
    const id_cycle = idCycle;
    let fileName = this.state.fileName;
    let fileContents = this.state.fileContents;

    const body = {
      fileName,
      fileContents,
    };
    const params = {
      id_cycle,
    };
    const res = await server(
      endpoints.registerEvents.route,
      endpoints.registerEvents.method,
      params,
      body
    );
    const { error_message, message: successMessage, data } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      message.success(successMessage);
      this.setState({ imageUrl: data });
    }
  };

  deleteEvents = async () => {
    const params = { id_cycle: this.props.idCycle };
    const res = await server(
      endpoints.deleteEvents.route,
      endpoints.deleteEvents.method,
      params
    );
    const { error_message, message: successMessage } = res;
    if (error_message) {
      message.error(error_message);
      return;
    } else {
      this.setState({ imageUrl: "" });
      message.success(successMessage);
    }
  };

  async componentDidMount() {
    await this.getEvents();
  }

  onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  encodeFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });

  beforeUpload = async (file) => {
    let converted_file = await this.encodeFile(file);
    this.setState({
      fileContents: converted_file,
      fileName: file.name,
      loadingEventPhoto: true,
      fileList: [],
    });
    this.postEvents();
  };

  render() {
    const uploadButton = <Button type="primary">Agregar</Button>;
    const { isMobile } = this.props;
    return (
      <Row align="center">
        {this.state.edit === true ? (
          <Row
            justify="center"
            align="center"
            gutter={[12]}
            style={{ margin: "1rem 1rem 1rem 1rem" }}
          >
            <Space>
              <Col>
                <Upload
                  beforeUpload={this.beforeUpload}
                  showUploadList={false}
                  accept=".pdf, .png, .jpg"
                >
                  {this.files ? null : uploadButton}
                </Upload>
              </Col>

              <Col>
                <Button type="primary" onClick={() => this.deleteEvents()}>
                  Eliminar
                </Button>
              </Col>
            </Space>
            <Divider />
            <Image
              src={this.state.imageUrl}
              alt=""
              style={{ maxWidth: `${this.props.screenWidth - 32}px` }}
            />
            <div
              align="flex-end"
              style={{
                right: "20px",
                bottom: "20px",
                display: "flex",
                position: "fixed",
              }}
            >
              <MassMailButton
                position="relative"
                idCycle={this.props.idCycle}
              />
            </div>
          </Row>
        ) : (
          <Row justify="center" align="center" gutter={[12]}>
            {!isMobile && <Divider />}
            <div
              style={{
                maxWidth: `${this.props.screenWidth - 32}px`,
                display: "flex",
              }}
            >
              <Image
                src={this.state.imageUrl}
                alt=""
                style={{ margin: "0 10rem 0 25rem" }}
              />
            </div>
          </Row>
        )}
      </Row>
    );
  }
}

export default Events;
