import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import {
  Table,
  Row,
  PageHeader,
  Input,
  Button,
  Space,
  Avatar,
  Typography,
  message,
} from "antd";
import { SearchOutlined, SkinOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import server, { endpoints } from "../utils/server";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import BaseDeDatos from "./evaluationsDataBase.jsx";

const { Text } = Typography;

const defaultColumns = [];


class DatabaseTH extends Component {
  state = {
    cyclesWithTeams: [],
    cycleId: this.props.idSelectedCycle,
    userId: this.props.idUR,
    users: [],
    usersInCycle: [],
    columns: defaultColumns,
    loaded: false,
    searchText: "",
    searchedColumn: "",
    hasShirt: 0,
    role: "Becario Trabajo",

    roles: []

    //Test*****************

    //EndTest*************
  };
  


  getRoles = async () => {
    const res = await server(
      endpoints.getRoles.route,
      endpoints.getRoles.method)
    if (res.error_message) {//si hay error se manda mensaje de error
      message.error(res.error_message)
    } else { // se guarda la vaiable en el estado 
      this.setState({ roles: res.data })
      console.log(this.state.roles);
    }




  }


  getAllUsersInCycleWithTeam = async () => {
    const { cycleId } = this.state;
    const res = await server(
      endpoints.getAllUsersInCycleWithTeams.route,
      endpoints.getAllUsersInCycleWithTeams.method,
      { id_cycle: cycleId }
    );
    const usersInCycle = res.data;
    for (let i = 0; i < usersInCycle.length; i++) {
      let fullBirthday = usersInCycle[i].birthday;
      let day = "",
        month = "",
        year = "";

      if (fullBirthday != null) {
        for (let j = 8; j < 10; j++) {
          day += fullBirthday[j];
        }
        for (let j = 5; j < 7; j++) {
          month += fullBirthday[j];
        }
        for (let j = 0; j < 4; j++) {
          year += fullBirthday[j];
        }

        usersInCycle[i].birthday = day + "/" + month + "/" + year;
      }
    }
    this.setState({ usersInCycle: usersInCycle });
    console.log("Gora: ", this.state.usersInCycle);
  };

  changeTShirtStatus = async (has_shirt, id) => {
    var hasShirtCondition;
    if (has_shirt) {
      hasShirtCondition = "0";
    } else {
      hasShirtCondition = "1";
    }
    try {
      const res = await server(
        endpoints.updateShirtStatus.route,
        endpoints.updateShirtStatus.method,
        {},
        { id_user: id, hasShirt: hasShirtCondition }
      );
      this.getAllUsersInCycleWithTeam();
      message.success(res.message);
    } catch (err) {
      message.error(err);
    }
  };

  getAllUsersInCycleExcel = async () => {
    const { cycleId, userId } = this.state;
    const queryString = new URLSearchParams({
      id_cycle: cycleId,
      idUR: userId,
    }).toString();
    const url = `${rootEndpoint()}api/cycles/users/excel?${queryString}`;

    try {
      window.location.href = url;
    } catch (error) {
      console.error("Error al obtener la URL de Excel:", error);
      return null;
    }
  };

  setColumns = () => {
    const defaultColumns = [
      {
        title: "Foto",
        dataIndex: "profile_photo_url",
        key: "profile_photo_url",
        render: (value, record) => <Avatar src={value} />,
      },
      {
        title: "Nombre",
        dataIndex: "first_name",
        key: "first_name",
        ...this.getColumnSearchProps("first_name", "Nombre"),
      },
      {
        title: "Apellido",
        dataIndex: "last_name",
        key: "last_name",
        ...this.getColumnSearchProps("last_name", "Apellido"),
      },
      {
        title: "Equipo",
        dataIndex: "team_name",
        key: "team_name",
        ...this.getColumnSearchProps("team_name", "Equipo"),
      },
      {
        title: "Sexo",
        dataIndex: "sex",
        key: "sex",
        ...this.getColumnSearchProps("sex", "Sexo"),
      },
      {
        title: "Rol",
        dataIndex: "role",
        key: "role",
        ...this.getColumnSearchProps("role", "Rol"),
      },
      {
        title: "Email Personal",
        dataIndex: "email",
        key: "email",
        ...this.getColumnSearchProps("email", "Email Personal"),
      },
      {
        title: "UP Email",
        dataIndex: "up_email",
        key: "up_email",
        ...this.getColumnSearchProps("up_email", "UP Email"),
      },
      {
        title: "Cumpleaños",
        dataIndex: "birthday",
        key: "birthday",
        ...this.getColumnSearchProps("birthday", "Cumpleaños"),
      },
      {
        title: "Semestre",
        dataIndex: "semester",
        key: "semester",
        ...this.getColumnSearchProps("semester", "Semestre"),
      },
      {
        title: "Preparatoria",
        dataIndex: "highschool",
        key: "highschool",
        ...this.getColumnSearchProps("highschool", "Preparatoria"),
      },
      {
        title: "Carrera",
        dataIndex: "career",
        key: "career",
        ...this.getColumnSearchProps("career", "Carrera"),
      },
      {
        title: "Celular",
        dataIndex: "phone_number",
        key: "phone_number",
        ...this.getColumnSearchProps("phone_number", "Celular"),
      },
      {
        title: "Carta de uso de imagen",
        dataIndex: "image_card_url",
        key: "image_card_url",
        render: (text) => text && <a href={text}>Carta de uso de imagen</a>,
      },
      {
        title: "Camisa",
        dataIndex: "has_shirt",
        key: "has_shirt",
        filters: [
          { text: "Tiene Camiseta", value: 1 },
          { text: "No tiene Camiseta", value: 0 },
        ],
        onFilter: (value, record) => {
          if (record.has_shirt === value) {
            return true;
          } else {
            return false;
          }
        },
        render: (hasShirt, user) => (
          <div>
            <SkinOutlined
              onClick={() => this.changeTShirtStatus(hasShirt, user.id)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: hasShirt ? "#66E30F" : "#E31C0F",
              }}
              {...this.state.usersInCycle}
            />
          </div>
        ),
      },
    ];

    let columns = defaultColumns;

    this.setState({ columns }, () => {
      this.setState({ loaded: true });
    });
  };

  async componentDidMount() {
    await this.state;
    //await this.getCycleCWithTeams();
    await this.getAllUsersInCycleWithTeam();
    this.setColumns();
    this.getRoles();
  }

  getColumnSearchProps = (dataIndex, searcher) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>

        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Buscar ${searcher}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const { usersInCycle, columns } = this.state;
    return (
      <React.Fragment>
        <div>
          <Row justify="center">
            <PageHeader title="Base de datos" />
          </Row>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Button type="primary" style={{ margin: "0px 30px", color:"#0099FF", backgroundColor:"rgba(0, 153, 255, 0.1)", border: "1px solid #0099FF", borderRadius: "6px", fontSize: "14.4px", fontWeight: "bold", width: "150px", height: "44px" }}>
              <NavLink to="/evaluationDataBase" Component={BaseDeDatos}>Evaluaciones</NavLink>
            </Button>
            <div style={{display:"flex", justifyContent:"center", flexDirection:"row", alignItems:"center"}}>
              <div type="button"  onClick={this.getAllUsersInCycleExcel} style={{display:"flex", justifyContent:"space-evenly", flexDirection:"row", alignItems:"center", cursor:"pointer", fontSize: "14.4px", color: "#5BBE59", fontWeight: "bold", backgroundColor:"rgba(91, 190, 89, 0.1)", borderRadius: "6px",border: "1px solid #5BBE59", height: "42px", width:"200px"}}>
                <VerticalAlignBottomOutlined style={{fontSize:"28px"}}/>Descargar ciclo actual
              </div>
            <div style={{ margin: "0px 30px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <select name="role" id="role" onChange={(e) => this.setState({ role: e.target.value })}
                style={{ width: "180px", height: "30px", fontSize: "16px", padding: "0}px 10px", borderRadius: "8px" }}>
                {this.state.roles.map((t) => {
                  return (
                    <option key={t.id} value={t.name}>
                      {t.name}
                    </option>
                  )
                })}
              </select>
              <div style={{ margin: "0px 30px", display: "flex", justifyContent: "space-between", flexDirection: "row", alignContent: "space-between" }}>
                <h3>Total resultados: </h3>
                <h3 style={{ marginLeft: "10px" }}>{usersInCycle.filter(user => user.role === this.state.role || (this.state.role === "Becario" && user.role && user.role.includes("Becario"))).length}</h3>
              </div>
            </div>
          </div>
          </div>

          <Table
            columns={columns}
            dataSource={usersInCycle}
            style={{ padding: "30px" }}
            scroll={{ x: 2000 }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default DatabaseTH;
