import React, { useState, useEffect } from "react";
import "./Emoji.css";
import HappyEmoji from "./HappyEmoji";
import SadEmoji from "./SadEmoji";
import MidEmoji from "./MidEmoji";
import { Button, Select, Input, Image, message } from "antd";
import highfive from "./assets/img/highfive.png";
import server, { endpoints } from "../../utils/server";
import moment from "moment";

const SatisfactionMeter = ({idExpoEvent, setActive}) => {
  useEffect(() => {
    getExpoEventData();
  }, []);

  const [isExternal, setIsExternal] = useState(false);
  const [isSadAnimated, setIsSadAnimated] = useState(false);
  const [isMidAnimated, setIsMidAnimated] = useState(false);
  const [isHappyAnimated, setIsHappyAnimated] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [selectedView, setSelectedView] = useState("emojiView");
  const [expoRoles, setExpoRoles] = useState([]);
  const [idAnswering, setIdAnswering] = useState("");
  const [voterRol, setVoterRol] = useState(null);
  const [type, setType] = useState("");

  const renderConditionalViews = () => {
    switch (selectedView) {
      case "emojiView":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <h2>Nos interesa tu opinion</h2>
            <h1>¿Cómo fue tu experiencia con la exposición?</h1>
            {isSelected ? <h3>{selectedText}</h3> : null}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "50%",
                width: "100%",
              }}
            >
              <SadEmoji
                onClick={onSadAnimationClick}
                isAnimated={isSadAnimated}
              />
              <MidEmoji
                onClick={onMidAnimationClick}
                isAnimated={isMidAnimated}
              />
              <HappyEmoji
                onClick={onHappyAnimationClick}
                isAnimated={isHappyAnimated}
              />
            </div>
            <Button
              type="primary"
              size="large"
              style={{ borderRadius: "14px", width: "35vw" }}
              onClick={onSendClick}
            >
              Enviar
            </Button>
          </div>
        );

      case "idFormView":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              position: "fixed",
            }}
          >
            <h1>Un último paso, ayudanos con tu información</h1>
            <div style={{ width: "40vw" }}>
              <h1>Soy</h1>
              <Select
                options={expoRoles}
                style={{ width: "100%" }}
                onChange={(e, m) => onRoleChange(e, m)}
              />
            </div>

            {isExternal ? null : (
              <div style={{ marginBottom: "5%", width: "40vw" }}>
                <h1>Mi ID es...</h1>
                <Input
                  onChange={(e) => {
                    onInputChange(e.target.value);
                  }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "40vw",
              }}
            >
              <Button
                type="primary"
                style={{ width: "100%", marginBottom: "2%" }}
                onClick={onIdSendClick}
              >
                Enviar
              </Button>

              <Button
                color="primary"
                variant="outlined"
                style={{ width: "100%" }}
                onClick={onIdReturnClick}
              >
                Regresar
              </Button>
            </div>
          </div>
        );

      case "thankYouView":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              position: "fixed",
            }}
          >
            <h1
              style={{
                fontSize: "2.5em",
              }}
            >
              ¡Listo, Muchas gracias por tu participación!
            </h1>
            <h1
              style={{
                fontSize: "2.5em",
              }}
            >
              Tu respuesta ha sido enviada
            </h1>
            <Image width={275} src={highfive} preview={false} />
          </div>
        );
    }
  };

  const getExpoEventData = async () => {
    const res = await server(
      endpoints.getExpoEventData.route,
      endpoints.getExpoEventData.method,
      { id_expo_event: idExpoEvent }
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {
      const expRoles = Object.keys(res.data.voterRolInterpretation).map(
        (key, index) => ({
          value: index,
          label: res.data.voterRolInterpretation[key],
        })
      );
      setExpoRoles(expRoles);
    }
  };

  const insertExpoEventData = async (answeringId) => {
    const dateNow = moment().format("YYYY-MM-DD HH:mm:ss");
    const params = { id_expo_event: idExpoEvent };
    const body = {
      id_answering: answeringId,
      voter_rol: voterRol,
      id_vote_type: parseInt(type) + 1,
      date: dateNow,
    }

    const res = await server(
      endpoints.insertExpoEventData.route,
      endpoints.insertExpoEventData.method,
      params,
      body
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {
      message.success("Guardado con exito");
    }
  };

  const onInputChange = (value) => {
    if (value != null) {
      setIdAnswering(value);
    }
  };

  const onRoleChange = (e, m) => {
    setVoterRol(e + 1);
    if (e === 5) {
      setIsExternal(true);
    } else {
      setIsExternal(false);
    }
  };

  const onSadAnimationClick = () => {
    setType("0");
    setIsSadAnimated(!isSadAnimated);
    setIsSelected(true);
    setIsMidAnimated(false);
    setIsHappyAnimated(false);
    setSelectedText("Puede Mejorar");
  };

  const onMidAnimationClick = () => {
    setType("1");
    setIsMidAnimated(!isMidAnimated);
    setIsSelected(true);
    setIsSadAnimated(false);
    setIsHappyAnimated(false);
    setSelectedText("Neutral");
  };

  const onHappyAnimationClick = () => {
    setType("2");
    setIsHappyAnimated(!isHappyAnimated);
    setIsSelected(true);
    setIsMidAnimated(false);
    setIsSadAnimated(false);
    setSelectedText("Me gustó");
  };

  const onSendClick = () => {
    if (!isSelected) {
      message.error("Debes seleccionar una respuesta");
    }
    else {
      setSelectedView("idFormView");
    }
  };

  const onIdSendClick = () => {
    if (!isExternal && (!idAnswering || idAnswering.length < 7) && idAnswering != "00000") {
      message.error("El ID o correo debe tener al menos 7 caracteres.");
      return; 
    }
    if (voterRol != null && voterRol !== 6 && idAnswering !== "00000") {
      insertExpoEventData(idAnswering);
      setSelectedView("thankYouView");
      const timer = setTimeout(() => {
        setType("");
        setVoterRol(null);
        setIsSelected(false);
        setIsSadAnimated(false);
        setIsMidAnimated(false);
        setIsHappyAnimated(false);
        setSelectedView("emojiView");
      }, 3500);
      return () => clearTimeout(timer);
    } else if (voterRol === 6 ) {
      setSelectedView("thankYouView");
      insertExpoEventData("N/A");
      const timer = setTimeout(() => {
        setType("");
        setVoterRol(null);
        setIsSelected(false);
        setIsSadAnimated(false);
        setIsMidAnimated(false);
        setIsHappyAnimated(false);
        setIsExternal(false);
        setSelectedView("emojiView");
      }, 3500);
      return () => clearTimeout(timer);
    } else if (voterRol === null || idAnswering === "00000") {
      setIdAnswering("");
      setActive(false);
    }
  };
  const onIdReturnClick = () => {
    setSelectedView("emojiView");
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        backgroundColor: "white",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        justifyItems: "center",
        zIndex: 100,
      }}
    >
      {renderConditionalViews(selectedView)}
    </div>
  );
};

export default SatisfactionMeter;
