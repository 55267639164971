import React, { Component } from "react";
import Login from "./views/login";
import MainPage from "./views/mainPage";
import "./App.css";
import server, { endpoints } from "./utils/server";
import { message } from "antd";

//const { wd } = useWindowDimensions();



class App extends Component {
  state = {
    loggedIn: false,
    idUR: 0,
    up_email: "",
    idSelectedCycle: 0,
  };

  constructor(props) {
    super(props);
    let tokenSaved = localStorage.getItem("auth");
    if (tokenSaved) {
      this.silentLogin();
    }
  }
  
  onSuccessfulLogin = (res) => {
    this.setState({ loggedIn: true })
    window.addEventListener("remove", () => {
      this.setState({ loggedIn: false });
      localStorage.setItem("auth", "");
    })
    localStorage.setItem("idUR", res.id);
    localStorage.setItem("up_email", res.up_email);
    localStorage.setItem("auth", res.accessToken);
    this.setState({
      idUR: parseInt(res.id),
      up_email: res.up_email,
    });
  };

  logOut = () => {
    server(endpoints.logout.route, endpoints.logout.method).then(res => {
      const { error_message } = res;
      if (error_message) {
        this.setState({
          idUR: this.state.idUR,
          up_email: this.state.up_email,
        });
        this.setState({ loggedIn: true })
        localStorage.setItem("idUR", this.state.idUR);
        localStorage.setItem("up_email", this.state.up_email);
        return;
      } else {
        this.setState({
          idUR: 0,
          up_email: "",
        });
        this.setState({ loggedIn: false })
        localStorage.setItem("auth", "");
        localStorage.setItem("idUR", 0);
        localStorage.setItem("up_email", "");
      }
    });
  };

  silentLogin() {
    server(endpoints.silentLogint.route, endpoints.silentLogint.method).then((res) => {
      const { error_message } = res;
      if (error_message) {
        message.error(error_message);
        return;
      }
      const { id: idUR, up_email } = res.data;
      localStorage.setItem("idUR", idUR);
      localStorage.setItem("up_email", up_email);
      this.setState({
        idUR: parseInt(idUR),
        up_email,
      });
      this.setState({ loggedIn: true })
      window.addEventListener("remove", () => {
        this.setState({ loggedIn: false })
      })
    })
  }


  handleCycleChange = (idNewSelectedCycle) => {
    if (idNewSelectedCycle === "new-cycle") {
      return;
    }
    this.setState({
      idSelectedCycle: idNewSelectedCycle,
    });
  };

  render() {
    const { idUR, up_email, idSelectedCycle, loggedIn } = this.state;

    return (
      <div>
        {loggedIn ? (
          <MainPage
            key={`Clico: ${idSelectedCycle}`}
            idUR={idUR}
            up_email={up_email}
            loggedIn={loggedIn}
            logOut={this.logOut}
            idSelectedCycle={idSelectedCycle}
            onCycleChange={this.handleCycleChange}
          />
        ) : (
          <Login onSuccessfulLogin={this.onSuccessfulLogin} key="login" />
        )

        }
      </div>
    );
  }
}


export default App;
