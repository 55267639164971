import React, { useState, useEffect } from "react";
import "../styles/StudentScholarshipCard.css";
import server, { endpoints } from "../utils/server";
import User from "../resources/images.jpg";
import EvaluationSidebar from "./Becas/evaluationSidebar";

const StudentScholarshipCard = ({ scholarship, onChange, idCycle, idTeam , idEvaluation, nameEvaluation}) => {
  const [roles, setRoles] = useState([]);
  const [status, setStatus] = useState([]);
  const [scholarshipData, setScholarshipData] = useState({ ...scholarship });
  const [idScholarship, setIdScholarship] = useState(null);

  useEffect(() => {
    setScholarshipData(scholarship);
  }, [scholarship]);
  
  useEffect(() => {
    getRoles();
    getScholarshipData();
  }, []);

  
  const getRoles = async () => {
    try {
      const res = await server(
        endpoints.getRoles.route,
        endpoints.getRoles.method
      );
      setRoles(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getScholarshipData = async () => {
    try {
      const res = await server(
        endpoints.getScholarshipData.route,
        endpoints.getScholarshipData.method
      );
      setStatus(res.data.scholarshipStatus);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedScholarship = {
      ...scholarshipData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : type === "number" ? parseFloat(value) ||  0: value,
    };
    setScholarshipData(updatedScholarship);
    onChange(updatedScholarship);
  };

  return (
    <div className="Student-Scholarship-Card">
      <div className="Top-Card">
        <div className="Card-Header">
          <div className="Photo">
            <img src={scholarshipData.profile_photo_url || User  } alt="scholarship-photo" onError={(e) => e.target.src = User} />
          </div>
          <h2 className="UserName">{scholarshipData.name}</h2>
        </div>
        <div className="Card-Btn" onClick={() => setIdScholarship(scholarship.id_user)}>Ver Encuesta</div>
        
      </div>
      <div className="Card-Body">
        <div className="Card-Left">
          <h3 className="Creditos">Créditos Tentativos</h3>
          <input
            type="number"
            className="CreditosInput"
            name="credits"
            value={scholarshipData.credits || 0}
            min={0}
            max={100}
            onChange={handleChange}
            onFocus={(e) => e.target.select()}
          />
          <h3 className="Puesto">Puesto</h3>
          <select
            className="PuestoInput"
            name="role"
            value={scholarshipData.role || ''}
            onChange={handleChange}
          >
            {roles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </select>
          <h3 className="Status">Status</h3>
          <select
            className="StatusInput"
            name="id_status"
            value={scholarshipData.id_status || ''}
            onChange={handleChange}
          >
            {status.map((status) => (
              <option key={status.id} value={status.id}>
                {status.type}
              </option>
            ))}
          </select>
        </div>
        <div className="Card-Center">
          <div className="Center-Info">
            <h2 className="BecaActual">Beca Actual</h2>
            <div className="Desglose-Aplicable">
              <div className="Desglose">
                <h3>Desglose de beca</h3>
                <div className="Porcentaje">
                  <p>Inscripción</p>
                  <div>
                    <input
                      type="number"
                      name="current_inscription_percentage"
                      style={{background : "white"}}
                      disabled
                      value={scholarshipData.current_inscription_percentage || 0}
                      onChange={handleChange}
                    />
                    %
                  </div>
                </div>
                <div className="Porcentaje">
                  <p>Colegiatura</p>
                  <div>
                    <input
                      type="number"
                      disabled
                      name="current_collegue_percentage"
                      value={scholarshipData.current_collegue_percentage || 0}
                      onChange={handleChange}
                      style={{background : "white"}}
                    />
                    %
                  </div>
                </div>
                <div className="Porcentaje Total">
                  <p>Total</p>
                  <div>
                    <input
                      type="number"
                      disabled
                      name="current_total_scholarship"
                      value={scholarshipData.current_total_scholarship || 0}
                      onChange={handleChange}
                      style={{background : "white"}}
                    />
                    %
                  </div>
                </div>
              </div>
              <div className="Aplicable">
                <h3>Aplicable a:</h3>
                <div>
                  <input
                    type="checkbox"
                    name="current_summer"
                    disabled
                    checked={scholarshipData.current_summer === 1}
                    onChange={handleChange}
                  />{" "}
                  Verano / Invierno
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="current_language"
                    disabled
                    checked={scholarshipData.current_language === 1}
                    onChange={handleChange}
                  />{" "}
                  Idioma
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Card-Right">
          <div className="Center-Info">
            <h2 className="BecaActual">Beca Futura</h2>
            <div className="Desglose-Aplicable">
              <div className="Desglose">
                <h3>Desglose de beca</h3>
                <div className="Porcentaje">
                  <p>Inscripción</p>
                  <div>
                    <input
                      type="number"
                      name="new_inscription_percentage"
                      value={scholarshipData.new_inscription_percentage || 0}
                      onChange={handleChange}
                      onFocus={(e) => e.target.select()}
                    />
                    %
                  </div>
                </div>
                <div className="Porcentaje">
                  <p>Colegiatura</p>
                  <div>
                    <input
                      type="number"
                      name="new_collegue_percentage"
                      value={scholarshipData.new_collegue_percentage || 0}
                      onChange={handleChange}
                      onFocus={(e) => e.target.select()}
                    />
                    %
                  </div>
                </div>
                <div className="Porcentaje Total">
                  <p>Total</p>
                  <div>
                    <input
                      type="number"
                      name="new_total_scholarship"
                      disabled
                      style={{background : "white"}}
                      value={scholarshipData.new_total_scholarship || 0}
                      onChange={handleChange}
                    />
                    %
                  </div>
                </div>
              </div>
              <div className="Aplicable">
                <h3>Aplicable a:</h3>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="new_summer"
                      checked={scholarshipData.new_summer === 1}
                      onChange={handleChange}
                    />
                    <span className="custom-checkbox"></span>
                    Verano / Invierno
                  </label>
                </div>
                <div>
                  <label>
                  <input
                    type="checkbox"
                    name="new_language"
                    checked={scholarshipData.new_language === 1}
                    onChange={handleChange}
                  />
                  <span className="custom-checkbox"></span>
                  Idioma
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        idScholarship !== null
          ? <EvaluationSidebar
            idCycle={idCycle}
            idTeam={idTeam}
            idEvaluation= {idEvaluation}
            idScholarship={idScholarship}
            setIdScholarship={setIdScholarship}
            imgUser={scholarshipData.profile_photo_url || User}
            nameEvaluation={nameEvaluation}
          />
          : null
      }
    </div>
  );
};

export default StudentScholarshipCard;
