import React, { useState, useEffect } from 'react';
import '../styles/ScholarshipReport.css';
import Arrow from '../resources/left-arrow.png';
import server, { endpoints } from "../utils/server";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { MinusCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

const ScholarshipReport = (idSelectedCycle) => {
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState(4);
    const [teamCostData, setTeamCostData] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [team, setTeam] = useState("");
    const [approved, setApproved] = useState(0);
    const ID = useParams();

    useEffect(() => {
        getTeamReview();
        getTeamsInCycle(idSelectedCycle.idCycle);
    }, [status]);

    const getTeamReview = async () => {
        const params = {
            id_cycle: idSelectedCycle.idCycle,
            id_team: ID.id, 
            id_scholarship_status: status
        };
    
        try {
            const res = await server(
                endpoints.getTeamReview.route,
                endpoints.getTeamReview.method,
                params
            );
    
            const data = res.data;
            setTeamCostData(data);
            setStatuses(data.infoScholarshipStatus);
            setUsers(data.infoCosts);
            setApproved(data.infoTeamsNewCycle?.is_approved);
        } catch (error) {
            console.error('Error fetching team cost:', error);
            throw error;
        }
    };
    const getTeamsInCycle = async (idCycle) => {
        try {
          const res = await server(
            endpoints.getTeamsInCycle.route,
            endpoints.getTeamsInCycle.method,
            { id_cycle: idCycle }
          );
          const teamsInCycle = res.data;
          const teamName = teamsInCycle.filter(
            (team) => team.id === Number(ID.id)
          )[0];
          setTeam(teamName.name);
        } catch (err) {
            console.error('Error fetching teams in cycle:', err);
            throw err;
        }
      };
    
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    return (
        <div className='Scholarship-Report'>
            <button className='Return' onClick={() => window.history.back()}>
                <img src={Arrow} alt="arrow" />
                Regresar
            </button>
            <div className='Title'>
                <h1>{team}</h1>
            </div>
            <div className='Content'>
                <div className="activos_inactivos">
                    <select value={status} onChange={handleStatusChange}>
                        {statuses?.map((status) => (
                            <option key={status.id} value={status.id}>
                                {status.type}
                            </option>
                        ))}
                    </select>
                    
                    <div className="user_container">
                        {users?.map((user) => (
                            <div key={user.id} className="user"> 
                                <div className="user_info">
                                    <div className="user_img">
                                        <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="user" />
                                    </div>
                                    <div className="user_data">
                                        <p className="name">{user.user}</p>
                                        <p className="id">{user.id}</p>
                                    </div>
                                </div>
                                <div className="money">${user.user_cost}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="table_button">
                    <div className="table_info">
                        <table>
                            <thead>
                                <tr>
                                    <th>Semestre</th>
                                    <th>Presupuesto Neto</th>
                                    <th>Presupuesto Al Momento</th>
                                    <th>Porcentaje de Beca</th>
                                    <th>Presupuesto Restante</th>
                                </tr>
                            </thead>
                            <tbody>
                                {teamCostData && (
                                    <>
                                        <tr>
                                            <td>Actual</td>
                                            <td>${teamCostData.infoTeamsCycleActive?.team_budget}</td>
                                            <td>${teamCostData.infoTeamsCycleActive?.spent_budget}</td>
                                            <td>{teamCostData.infoTeamsCycleActive?.scholarship_total}%</td>
                                            <td>${teamCostData.infoTeamsCycleActive?.team_budget - teamCostData.infoTeamsCycleActive?.spent_budget || 0}</td>
                                        </tr>
                                        <tr>
                                            <td>Próximo</td>
                                            <td>${teamCostData.infoTeamsNewCycle?.team_budget}</td>
                                            <td>${teamCostData.infoTeamsNewCycle?.spent_budget}</td>
                                            <td>{teamCostData.infoTeamsNewCycle?.scholarship_total}%</td>
                                            <td>${teamCostData.infoTeamsNewCycle?.team_budget - teamCostData.infoTeamsNewCycle?.spent_budget || 0}</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {approved === 1 ? (
                        <div className="estatusPresupuesto active">
                            <div className="circle active"></div>
                            <div className="info">
                                <p className='title active'><CheckCircleOutlined />Confirmado</p>
                                <p>Presupuesto rentable</p>
                            </div>
                        </div>
                    ) : (
                        <div className="estatusPresupuesto">
                            <div className="circle"></div>
                            <div className="info">
                                <p className='title'><MinusCircleOutlined />Rechazado</p>
                                <p>Presupuesto no rentable</p>
                            </div>
                        </div>
                    )}

                    
                </div>
            </div>
        </div>
    );
};

export default ScholarshipReport;

