import React, { useState } from 'react';
import server, { endpoints } from "../../utils/server.js";
import './modalExposEvents.css';

const ModalExposEvents = ({ isOpen, onClose}) => {
  const [eventName,setEventName]=useState("");
  if (!isOpen) return null;

  const insertExpoEvent = async () => {
    const res = await server(
      endpoints.insertExpoEvent.route,
      endpoints.insertExpoEvent.method,
      { },
      { name:eventName}
    );
    if (res.error_message) {
      alert(res.error_message);
    } else {
      alert(res.message);
      
    }
  };

  const handleName = (event) => {
    setEventName(event.target.value);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>X</button>
        <h2>Crea un evento</h2>
        <p>Ingresa el nombre del evento a crear.</p>
        <div className='Modal-input-buttons-container'>
        <input type="text" className='Modal-search-input'
        onChange={(e) => {
          handleName(e);
        }}
        value={eventName}
        ></input>
        <div className="Modal-buttons-container">
            <button className='Modal-cancel-button' 
            onClick={
              ()=>{setEventName("");
                onClose();
              }} >Cancelar</button>
            <button className='Modal-event-button'
            onClick={
              ()=>{insertExpoEvent();
                onClose();
              }}>Crear Evento</button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ModalExposEvents;