import React, { useEffect, useState } from 'react';
import "./style/evaluationSidebar.css";
import server, { endpoints } from "../../utils/server";
import Arrow from '../../resources/left-chevron.png';
import User from '../../resources/images.jpg';

function EvaluationSidebar({ idTeam, idScholarship, idCycle, setIdScholarship, imgUser, emailUser , idEvaluation, nameEvaluation}) {
    const [answers, setAnswers] = useState([]);
    const [evaluation, setEvaluation] = useState("");
    const [user, setUser] = useState(null);
    const [isVisible, setIsVisible] = useState(true);
    const [isExiting, setIsExiting] = useState(false);

    useEffect(() => {
        if (idTeam && idScholarship && idCycle) {
            getEvaluationAnswers();
            // getAllUserEvaluationsReport();
            getTeamUsers();
        }
    }, [idTeam, idScholarship, idCycle]);

     const handleOutsideClick = (e) => {
        if (e.target.className === "Evaluation-Sidebar ") {
            handleExit();
        }
    };

    const handleExit = () => {
        setIsExiting(true);
        setTimeout(() => {
            setIsVisible(false);
            setIdScholarship(null);
        }, 200);
    };

    // const getAllUserEvaluationsReport = async () => {
    //     const params = { id_team: idTeam, id_user: idScholarship, id_cycle: idCycle };
    //     const res = await server(endpoints.getAllUserEvaluationsReport.route, endpoints.getAllUserEvaluationsReport.method, params);
    //     if (!res.error_message) {
    //         const evaluations = res.data?.usersTeamEvaluations || [];
    //         const filteredEvaluations = evaluations.find(i => i.id_evaluation === 79);
    //         setEvaluation(filteredEvaluations?.evaluation_name || "");
    //     } else {
    //         setEvaluation("");
    //     }
    // };

    const getEvaluationAnswers = async () => {
        const params = { id_team: idTeam, id_evaluation: idEvaluation, id_user: idScholarship };
        const res = await server(endpoints.getEvaluationAnswers.route, endpoints.getEvaluationAnswers.method, params);
        if (!res.error_message) {
            setAnswers(res.data || []);
        } else {
            setAnswers([]);
        }
    };

    const getTeamUsers = async () => {
        const params = { id_cycle: idCycle, id_team: idTeam, id: idScholarship };
        const res = await server(endpoints.getTeamUsers.route, endpoints.getTeamUsers.method, params);
        if (!res.error_message) {
            const users = res.data?.users || [];
            const filteredUser = users.find(i => i.id === idScholarship);
            setUser(filteredUser || null);
        } else {
            setUser(null);
        }
    };

    if (!isVisible) return null; 

    return (
        <div className={`Evaluation-Sidebar ${isExiting ? 'exit' : ''}`} onClick={e => { e.stopPropagation(); handleOutsideClick(e); }}>
            <div className="Evaluation-Sidebar-Container">
                <div className='Evaluation-Sidebar-btn'>
                    <button onClick={() => handleExit()} className='Evaluation-Sidebar-btn-back'>
                        <img src={Arrow} alt='arrow' className='Evaluation-Sidebar-arrow' />Regresar
                    </button>
                </div>
                <div className='Evaluation-Sidebar-Header'>
                    <img src={imgUser || User} alt="User" className='Evaluation-Sidebar-imgUser' onError={(e) => e.target.src = User} />
                    <div className='Evaluation-Sidebar-Names'>
                        <h2 className='Evaluation-Sidebar-evaluation'>{nameEvaluation}</h2>
                        <h3 className='Evaluation-Sidebar-user'>{user?.first_name} {user?.last_name}</h3>
                    </div>
                </div>
                <div className='Evaluation-Sidebar-Info'>
                    <div className='Evaluation-Sidebar-Info-Container'>
                        <p className='Evaluation-Sidebar-Info-Container-Item-Title'>Información Académica</p>
                        <div className='Evaluation-Sidebar-Info-Container-Item-Container'>
                            <p className='Evaluation-Sidebar-Info-Container-Item'>Correo Inst: </p>
                            <p className='Evaluation-Sidebar-Info-Container-Item-Value'> {user?.up_email}</p>
                        </div>
                        <div className='Evaluation-Sidebar-Info-Container-Item-Container'>
                            <p className='Evaluation-Sidebar-Info-Container-Item'>Programa: </p>
                            <p className='Evaluation-Sidebar-Info-Container-Item-Value'>{user?.career}</p>
                        </div>
                        <div className='Evaluation-Sidebar-Info-Container-Item-Container'>
                            <p className='Evaluation-Sidebar-Info-Container-Item'>Semestre: </p>
                            <p className='Evaluation-Sidebar-Info-Container-Item-Value'> {user?.semester}</p>
                        </div>
                    </div>
                    <div className='Evaluation-Sidebar-Answers'>
                        {
                            answers[0]?.answers?.length!==0 ?
                                answers.map((answer, index) => (
                                    <div className='Evaluation-Sidebar-Answers-Container' key={index}>
                                        <p className='Evaluation-Sidebar-Answers-Container-Title'>{index + 1}. {answer?.question}</p>
                                        <p className='Evaluation-Sidebar-Answers-Container-Answer'>{answer.answers[0]?.answer || "No hay respuesta"}</p>
                                    </div>
                                ))
                                : <p className='Evaluation-Sidebar-Answers-Container-Title'>No hay respuestas</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EvaluationSidebar;
