import React, { useEffect, useState } from "react";

import "./TeamsDB.css";
import { Col, Row } from "antd";

const TeamsDB = ({ team }) => {
  /*const [activeTeam, setActiveTeam] = useState();
  useEffect(() => {
    setActiveTeam(team);
  }, [team]);*/
  if (team != undefined) {
    return (
      <div>
        <Row className="Teams-DB-topRow">
          <Col span={9} className="Teams-DB-Col">
            <Col
              span={9}
              className="Teams-DB-Col t-info"
              style={{
                background: "#039BE5",
              }}
              key={team.id}
            >
              <Col
                span={20}
                className="t-name"
                style={{ color: team === team.id && "#ffffff" }}
              >
                <Row>{team.name}</Row>
                <Row span={10} offset={1}>
                  {team.users_answered}/{team.users_in_team}
                </Row>
              </Col>
            </Col>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="Teams-DB-topDiv">
        <Row className="Teams-DB-topRow">
          <Col span={9} className="Teams-DB-Col">
            <Col
              span={9}
              className="Teams-DB-Col t-info"
              style={{
                background: "#A6ACAF",
              }}
            >
              <Col span={20} className="t-name" style={{ color: "#ffffff" }}>
                <Row>{"Sin equipo"}</Row>
                <Row span={10} offset={1}>
                  {"0 miembros"}
                </Row>
              </Col>
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
};

export default TeamsDB;
