import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Typography,
  Divider,
  Select,
  Table,
  message,
  Input,
  Tooltip,
  Modal,
} from "antd";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import {
  ArrowLeftOutlined,
  ExclamationOutlined,
  EllipsisOutlined,
  SaveOutlined,
  MailOutlined,
} from "@ant-design/icons";
import server, { endpoints } from "../utils/server";

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ScholarshipGeneralInfo = ({ idCycle }) => {
  const [scholarshipType, setScholarshipType] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [evaluationSelected, setEvaluationSelected] = useState();

  useEffect(() => {
    getScholarshipBudget(0);
    getTeamsCost(0);
    getScholarshipData();
    getEvaluation();
  }, []);

  // const scholarshipType = [
  //   { id: 1, text: "Cultural" },
  //   { id: 2, text: "Trabajo" },
  // ];

  const getScholarshipData = async () => {
    const res = await server(
      endpoints.getScholarshipData.route,
      endpoints.getScholarshipData.method
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {
      setScholarshipType(res.data.scholarshipType);
    }
  };
  const getEvaluation = async () => {
    const params = {
      id_cycle: idCycle,
    };
    const res = await server(
      endpoints.getEvaluation.route,
      endpoints.getEvaluation.method,
      params
    );
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    }

    setEvaluations(res.data);
  };
  const defaultColumns = [
    {
      title: "Departamento",
      dataIndex: "team_name",
      key: "team_name",
      align: "center",
      width: "40%",
      render: (team_name, teamsCostArray) => (
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>{team_name ? team_name : "n/a"}</p>
          <Select
            defaultValue={teamsCostArray.id_scholarship_type}
            style={{ width: "40%" }}
            onChange={(id) => {
              handleScholarshipTypeChange(id, teamsCostArray.id_team);
            }}
          >
            {scholarshipType.map((t) => {
              return (
                <Option key={t.id} value={t.id}>
                  {t.type}
                </Option>
              );
            })}
            ,
          </Select>
        </Row>
      ),
    },
    {
      title: "Presupuesto pasado",
      dataIndex: "team_budget",
      key: "team_budget",
      align: "center",
      render: (team_budget) => (
        <p>
          {"$ "}
          {team_budget ? team_budget : 0}
        </p>
      ),
    },
    {
      title: "Presupuesto actual",
      dataIndex: "future_team_budget",
      key: "future_team_budget",
      align: "center",
      render: (future_team_budget) => (
        <p>
          {"$ "}
          {future_team_budget ? future_team_budget : 0}
        </p>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "is_approved",
      key: "is_approved",
      align: "center",
      width: "20%",
      render: (is_approved) =>
        is_approved ? (
          <button
            style={{
              backgroundColor: "#DEF7EC",
              color: "#02543F",
              border: "none",
              borderRadius: "14px",
            }}
          >
            Aprobado
          </button>
        ) : (
          <button
            style={{
              backgroundColor: "#FBD5D5",
              color: "#9B1C1C",
              border: "none",
              borderRadius: "14px",
            }}
          >
            En proceso
          </button>
        ),
    },
    {
      title: "Cambiar confirmacion",
      dataIndex: "is_approved",
      key: "is_approved",
      align: "center",
      render: (is_approved, teamsCostArray) =>
        is_approved ? (
          <Tooltip title="Presionar este botón cambiara el estado de la beca de confirmado a en proceso">
            <Button
              shape="circle"
              onClick={() =>
                handleApprovalChange(is_approved, teamsCostArray.id_team)
              }
              style={{
                width: "20px",
                backgroundColor: "#DEF7EC",
              }}
            >
              <ExclamationOutlined
                style={{
                  color: "#DEF7EC",
                }}
              />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Presionar este botón cambiara el estado de la beca de en proceso a confirmado">
            <Button
              onClick={() =>
                handleApprovalChange(is_approved, teamsCostArray.id_team)
              }
              shape="circle"
              style={{
                width: "20px",
                backgroundColor: "#FBD5D5",
              }}
            >
              <ExclamationOutlined
                style={{
                  color: "#FBD5D5",
                }}
              />
            </Button>
          </Tooltip>
        ),
    },
    {
      title: "Fecha de aprobación",
      dataIndex: "time_approval",
      key: "time_approval",
      align: "center",
      render: (time_approval) => (
        <p>{time_approval ? time_approval.split("T")[0] : "n/a"}</p>
      ),
    },
  ];

  const [scholarshipBudget, setScholarshipBudget] = useState();
  const [teamsCostArray, setTeamsCostArray] = useState([]);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [messageText, setMessageText] = useState("");

  const getScholarshipBudget = async (idCycle) => {
    try {
      const res = await server(
        endpoints.getScholarshipBudget.route,
        endpoints.getScholarshipBudget.method,
        { id_cycle: idCycle }
      );
      setScholarshipBudget(res.data);
      setEvaluationSelected(res.data.id_evaluation);
    } catch (err) {
      message.error(err);
    }
  };

  const getTeamsCost = async (idCycle) => {
    const res = await server(
      endpoints.getTeamsCost.route,
      endpoints.getTeamsCost.method,
      { id_cycle: idCycle }
    );
    if (res.error_message) {
      message.error(res.error_message);
      return;
    } else {
      setTeamsCostArray(res.data.scholarship);
    }
  };
  const insertScholarshipBudget = async () => {
    const params = {
      id_evaluation: evaluationSelected,
    };

    try {
      const res = await server(
        endpoints.insertScholarshipBudget.route,
        endpoints.insertScholarshipBudget.method,
        params,
        {
          total_budget: scholarshipBudget.total_budget,
          credit_cost: scholarshipBudget.credit_cost,
          budget_per_cycle: scholarshipBudget.budget_per_cycle,
          inscription_cost: scholarshipBudget.inscription_cost,
        }
      );
      message.success(res.message);
    } catch (err) {
      message.error(err);
    }
  };

  const insertScholarshipMessage = async () => {
    const res = await server(
      endpoints.insertScholarshipMessage.route,
      endpoints.insertScholarshipMessage.method,
      { id_cycle: 0 },
      { message: messageText }
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {
      message.success(res.message);
    }
  };

  const updateScholarshipType = async (newId, teamId) => {
    const res = await server(
      endpoints.updateScholarshipType.route,
      endpoints.updateScholarshipType.method,
      {
        id_cycle: 0,
        id_team: teamId,
        id_scholarship_type: newId,
      }
    );

    if (res.error_message) {
      message.error(res.error_message);
    } else {
      message.success(res.message);
    }
  };

  const updateScholarshipApproval = async (approvalState, selectedTeam) => {
    const res = await server(
      endpoints.updateScholarshipApproval.route,
      endpoints.updateScholarshipApproval.method,
      {
        id_cycle: 0,
        id_team: selectedTeam,
        is_approved: approvalState,
      }
    );

    if (res.error_message) {
      message.error(res.error_message);
    } else {
      message.success(res.message);
    }
    getTeamsCost(0);
  };

  const handleApprovalChange = (isApproved, teamId) => {
    const approvalState = isApproved ? 0 : 1;
    const selectedTeam = teamId;
    updateScholarshipApproval(approvalState, selectedTeam);
  };

  const handleScholarshipTypeChange = (id, teamId) => {
    const newId = id;
    const selectedTeam = teamId;

    updateScholarshipType(newId, selectedTeam);
  };

  const handleMessageModalOpen = () => {
    setIsMessageModalOpen(!isMessageModalOpen);
  };

  const handleMessageModalCancel = () => {
    setIsMessageModalOpen(!isMessageModalOpen);
  };

  const handleMessageModalOk = () => {
    insertScholarshipMessage();
    setIsMessageModalOpen(!isMessageModalOpen);
    setMessageText("");
  };

  return (
    <div>
      <Row
        style={{
          display: "flex",
          alignItems: "baseline",
          alignContent: "center",
          paddingTop: "1%",
          paddingLeft: "1%",
        }}
      >
        <NavLink to="scholarships">
          <Button icon={<ArrowLeftOutlined />} />
        </NavLink>
        <h4 style={{ paddingLeft: "1%" }}>Regresar al menú</h4>
      </Row>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateAreas: `"a b c"`,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Title
          level={2}
          style={{
            gridArea: "b",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          General
        </Title>

        <Button
          onClick={handleMessageModalOpen}
          shape="circle"
          icon={<MailOutlined />}
          style={{
            gridArea: "c",
            backgroundColor: "#1892ff",
            color: "#FFFFFF",
          }}
        />
      </div>
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <Divider
          style={{
            width: "100%",
            height: "0.8px",
            backgroundColor: "#000000",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "60%",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "170px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#abcaff",
                  width: "170px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "2px",
                }}
              >
                <h4>Presupuesto total</h4>
              </div>
              <div
                style={{
                  width: "170px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5%",
                }}
              >
                <h5 style={{ color: "#979797" }}>Cantidad</h5>
              </div>
              <Input
                value={scholarshipBudget?.total_budget}
                onChange={(e) =>
                  setScholarshipBudget({
                    ...scholarshipBudget,
                    total_budget: parseInt(e.target.value || 0),
                  })
                }
                style={{
                  width: "70%",
                }}
                prefix="$"
              />
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "170px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#abcaff",
                  width: "170px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "2px",
                }}
              >
                <h4>Costo del Crédito</h4>
              </div>
              <div
                style={{
                  width: "170px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5%",
                }}
              >
                <h5 style={{ color: "#979797" }}>Cantidad</h5>
              </div>
              <Input
                value={scholarshipBudget?.credit_cost}
                onChange={(e) =>
                  setScholarshipBudget({
                    ...scholarshipBudget,
                    credit_cost: parseInt(e.target.value || 0),
                  })
                }
                style={{
                  width: "70%",
                }}
                prefix="$"
              />
            </div>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "170px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#abcaff",
                  width: "170px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "2px",
                }}
              >
                <h4>Costo de inscripción</h4>
              </div>
              <div
                style={{
                  width: "170px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5%",
                }}
              >
                <h5 style={{ color: "#979797" }}>Cantidad</h5>
              </div>
              <Input
                value={scholarshipBudget?.inscription_cost}
                onChange={(e) =>
                  setScholarshipBudget({
                    ...scholarshipBudget,
                    inscription_cost: parseInt(e.target.value || 0),
                  })
                }
                style={{
                  width: "70%",
                }}
                prefix="$"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "170px",
            }}
          >
            <div
              style={{
                backgroundColor: "#abcaff",
                width: "170px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "2px",
              }}
            >
              <h4>Presupuesto del semestre</h4>
            </div>
            <div
              style={{
                width: "170px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5%",
              }}
            >
              <h5 style={{ color: "#979797" }}>Cantidad</h5>
            </div>
            <Input
              value={scholarshipBudget?.budget_per_cycle}
              onChange={(e) =>
                setScholarshipBudget({
                  ...scholarshipBudget,
                  budget_per_cycle: parseInt(e.target.value || 0),
                })
              }
              style={{
                width: "70%",
              }}
              prefix="$"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", paddingTop: "1%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "#e5e5e5",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "2px",
              }}
            >
              <h4>Presupuesto disponible</h4>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5%",
              }}
            >
              <h5 style={{ color: "#979797" }}>Cantidad</h5>
            </div>
            <Input
              value={scholarshipBudget?.remaining_budget}
              disabled
              style={{
                width: "70%",
              }}
              prefix="$"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              paddingLeft: "40px",
            }}
          >
            <div
              style={{
                backgroundColor: "#e5e5e5",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "2px",
              }}
            >
              <h4 style={{ textAlign: "center" }}>
                Presupuesto aproximado del semestre
              </h4>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5%",
              }}
            >
              <h5 style={{ color: "#979797" }}>Cantidad</h5>
            </div>
            <Input
              value={scholarshipBudget?.budget_per_cycle}
              disabled
              style={{
                width: "70%",
              }}
              prefix="$"
            />
          </div>
        </div>
        <div style={{ width: "30%" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "5%",
            }}
          >
            <h4>Presupuesto del semestre</h4>
            <h4>
              {"$ "}
              {scholarshipBudget?.budget_per_cycle}
            </h4>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <h4>Gastos</h4>
            <h4>
              {"$ "}
              {scholarshipBudget?.spent_budget}
            </h4>
          </div>
          <hr style={{ width: "80%" }} />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <h4>Presupuesto restante</h4>
            <h4>
              {"$ "}
              {scholarshipBudget?.budget_per_cycle -
                scholarshipBudget?.spent_budget}
            </h4>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "20px",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Button
          style={{
            backgroundColor: "#1892ff",
            color: "#FFFFFF",
            borderRadius: "14px",
          }}
          icon={<SaveOutlined />}
          onClick={() => {
            insertScholarshipBudget();
          }}
        >
          {" "}
          Guardar
        </Button>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "70%",
            height: "66px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h3>Presupuesto</h3>
            <h4>Lista de presupuesto vigente</h4>
          </div>
          <select
            name="evaluations"
            id="evaluations"
            onChange={(e) => setEvaluationSelected(e.target.value)}
            value={evaluationSelected}
            style={{
              width: "270px",
              height: "44px",
              fontSize: "16px",
              padding: "0px 10px",
              borderRadius: "10px",
              border: "2px solid rgba(204,204,204,0.8)",
              background: "#f5f5f5",
              color: "#cccccc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <option value="0" disabled>
              Seleccionar encuesta
            </option>
            {evaluations.map((e) => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>

        <Table
          style={{ width: "70%" }}
          columns={defaultColumns}
          dataSource={teamsCostArray}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <NavLink to="katiaScholarshipDownload">
            <Button
              style={{
                height: "100%",
                borderRadius: "14px",
                backgroundColor: "#1892ff",
                color: "#FFFFFF",
                border: "none",
              }}
            >
              <Row>
                <EllipsisOutlined rotate="90" style={{ fontSize: "18px" }} />
                <div>
                  <h4 style={{ color: "#FFFFFF" }}>Revision</h4>
                  <h4 style={{ color: "#FFFFFF" }}>Tabla de contenidos</h4>
                </div>
              </Row>
            </Button>
          </NavLink>
        </div>
      </div>
      <Modal
        open={isMessageModalOpen}
        onOk={handleMessageModalOk}
        onCancel={handleMessageModalCancel}
        title="Enviar aviso"
      >
        <Title level={4}>Mensaje:</Title>
        <TextArea
          placeholder="Agregar aviso"
          rows={4}
          onChange={(e) => {
            setMessageText(e.target.value);
          }}
        />
      </Modal>
    </div>
  );
};

export default ScholarshipGeneralInfo;
