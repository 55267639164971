
import { rootEndpoint } from "../utils/constants";
import React, { useState } from "react";
import './styles/attendanceReport.css';
import { DatePicker } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

const AttendanceReport = ({ idUR, idSelectedCycle }) => {
  const [dateRange, setDateRange] = useState([null, null]);

  const handleGenerateExcel = async () => {
    const [startDate, endDate] = dateRange.map(date => dayjs(date).format(dateFormat.replace(/\//g, '-')));

    if (!startDate || !endDate) {
      console.error('Seleccione un rango de fechas válido.');
      return;
    }

    try {
      const url = await getExcelUrl(startDate, endDate);
      if (url) {
        window.location.href = url;

      } else {
        console.error('No se pudo obtener la URL del archivo Excel');
      }
    } catch (error) {
      console.error('Error generando Excel:', error);
    }
  };

  const getExcelUrl = async (startDate, endDate) => {
    if (!idSelectedCycle) {
      console.error('idSelectedCycle es nulo o no definido');
      return null;
    }
  
    const params = {
      idUR: idUR,
      id_cycle: idSelectedCycle,
      start_date: startDate,
      end_date: endDate,
    };
  
    const queryString = new URLSearchParams(params).toString();
    const url = `${rootEndpoint()}api/tasks/office_tasks/excel?${queryString}`;
  
    try {
      return url;
    } catch (error) {
      console.error('Error al obtener la URL de Excel:', error);
      return null;
    }
  };

  return (
    <div className="Attendance-Report" style={{ backgroundColor: 'white' }}>
      <RangePicker
        className={"createDateRangePicker"}
        dropdownClassName={"createDateRangePicker"}
        name="StartEndDate"
        size="large"
        placeholder={["Fecha Inicio", "Fecha Fin"]}
        format={dateFormat}
        onChange={value => setDateRange(value)}
      />
      <input onClick={handleGenerateExcel} type="button" className="To-DB-Button" value="Descargar base de datos"/>
    </div>
  );
};


export default AttendanceReport;
