import React, { useState, useEffect } from "react";
import "../styles/StudentScholarshipInfo.css";
import {
  BellFilled,
  BarChartOutlined,
  SaveFilled,
  CloseOutlined,
} from "@ant-design/icons";
import Arrow from "../resources/left-arrow.png"; // Imagen para flecha izquierda
import ArrowRight from "../resources/right-arrow.png"; // Imagen para flecha derecha
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useParams, useHistory } from "react-router";
import server, { endpoints } from "../utils/server";
import StudentScholarshipCard from "./studentScholarshipCard";
import { message } from "antd";
import { scholarshipGeneralPerms } from "../utils/constants";

const StudentScholarshipInfo = ({ idCycle , idUR}) => {
  const params = useParams();
  const history = useHistory();
  const [scholarshipInfo, setScholarshipInfo] = useState([]);
  const [currentTeam, setCurrentTeam] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasUnSavedChanges, setHasUnSavedChanges] = useState(false);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const scholarshipsPerPage = 2;
  const [showModal, setShowModal] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [scholarshipMessage, setScholarshipMessage] = useState("");
  const [scholarshipBudget, setScholarshipBudget] = useState([]);
  const [idEvaluation, setIdEvaluation] = useState();
  const [nameEvaluation, setNameEvaluation] = useState();
  const [teamPerms, setTeamPerms] = useState(false);

  useEffect(() => {
    getScholarship(idCycle, params.id);
    getTeamsInCycle(idCycle);
    getScholarshipMessage(0);
    getScholarshipBudget();
    getProfesionalTeams(idCycle);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnSavedChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnSavedChanges]);

  const getProfesionalTeams = async (idCycle) => {
    const res = await server(
      endpoints.getProfesionalTeams.route,
      endpoints.getProfesionalTeams.method,
      { id_cycle: idCycle }
    );

    if (res.error_message) {
      message.error(res.error_message);
      return;
    } else {
      const teams = res.data;
      const team = teams.find((e)=>e.Id_team == params.id)

      setTeamPerms(team.is_profesional);
    }
  };

  const getScholarship = async (idCycle, idTeam) => {
    try {
      const res = await server(
        endpoints.getScholarship.route,
        endpoints.getScholarship.method,
        { id_cycle: idCycle, id_team: idTeam }
      );
      setScholarshipInfo(res.data.scholarship);
    } catch (err) {
      console.error(err);
    }
  };

  const getScholarshipBudget = async () => {
    try {
      const res = await server(
        endpoints.getScholarshipBudget.route,
        endpoints.getScholarshipBudget.method,
        { id_cycle: 0 }
      );
      setScholarshipBudget(res.data);
      setIdEvaluation(res.data.id_evaluation);
      getEvaluationName(res.data.id_evaluation);
    } catch (err) {
      console.error(err);
    }
  };
  const getEvaluationName = async (id) => {
    const params = {
      id_cycle: idCycle,
    };
    const res = await server(
      endpoints.getEvaluation.route,
      endpoints.getEvaluation.method,
      params
    );
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    const evaluations = res.data;
    const evaluationName = evaluations.filter((e) => e.id == id)[0]?.name;
    setNameEvaluation(evaluationName);
  };

  const getTeamsInCycle = async (idCycle) => {
    try {
      const res = await server(
        endpoints.getTeamsInCycle.route,
        endpoints.getTeamsInCycle.method,
        { id_cycle: idCycle }
      );
      const teamsInCycle = res.data;
      const teamName = teamsInCycle.filter(
        (team) => team.id === Number(params.id)
      )[0];
      setCurrentTeam(teamName);
    } catch (err) {
      console.error(err);
    }
  };

  const getScholarshipMessage = async (idCycle) => {
    try {
      const res = await server(
        endpoints.getScholarshipMessage.route,
        endpoints.getScholarshipMessage.method,
        { id_cycle: idCycle }
      );

      const message = res.data.message;
      setScholarshipMessage(message);
      // Obtener los mensajes vistos del localStorage (puede que no existan, por eso || [])
      const seenMessages =
        JSON.parse(localStorage.getItem("seenMessages")) || [];

      // Verificar si este mensaje ya ha sido visto
      if (!seenMessages.includes(message)) {
        setHasNewMessage(true); // Mostrar la bolita roja si el mensaje no ha sido visto
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleNotificationClick = () => {
    setShowModalMessage(true);
    setHasNewMessage(false); // Desactivar la bolita roja

    const seenMessages = JSON.parse(localStorage.getItem("seenMessages")) || [];

    // Usar el mensaje desde el estado `scholarshipMessage`
    const updatedSeenMessages = [
      ...new Set([...seenMessages, scholarshipMessage]),
    ];
    localStorage.setItem("seenMessages", JSON.stringify(updatedSeenMessages));
  };

  const handleCardChange = (updatedScholarship) => {
    setScholarshipInfo((prevInfo) => {
      const newInfo = prevInfo.map((scholarship) =>
        scholarship.id_user === updatedScholarship.id_user
          ? updatedScholarship
          : scholarship
      );
      // Verifica si realmente hubo algún cambio en los datos
      const hasChanges = JSON.stringify(prevInfo) !== JSON.stringify(newInfo);

      // Solo actualiza el estado de 'hasUnSavedChanges' si hubo cambios reales
      if (hasChanges) {
        setHasUnSavedChanges(true);
      }
      return newInfo;
    });
  };

  const handleSave = async () => {
    try {
      const param = {
        id_cycle: idCycle,
        id_team: params.id,
      };

      const body = {
        scholarship: scholarshipInfo.map((scholarship) => ({
          id_user: scholarship.id_user,
          credits: scholarship.credits || 0,
          inscription_percentage: scholarship.new_inscription_percentage || 0,
          collegue_percentage: scholarship.new_collegue_percentage || 0,
          summer: scholarship.new_summer || 0,
          language: scholarship.new_language || 0,
          id_status: scholarship.id_status || 2,
          id_role: scholarship.role,
        })),
      };

      const res = await server(
        endpoints.insertScholarship.route,
        endpoints.insertScholarship.method,
        param,
        body
      );
      message.success(res.message);
      await getScholarship(idCycle, params.id);
      setHasUnSavedChanges(false);
    } catch (err) {
      console.error("Error al guardar los datos: ", err);
    }
  };

  const handleBackClick = () => {
    if (hasUnSavedChanges) {
      setShowModal(true);
    } else {
      history.goBack();
    }
  };

  const confirmLeave = () => {
    setShowModal(false);
    setHasUnSavedChanges(false);
    history.goBack();
  };

  const cancelLeave = () => {
    setShowModal(false);
  };

  const handleModalClose = () => {
    setShowModalMessage(false);
  };

  // Calcular el índice de las becas a mostrar
  const indexOfLastScholarship = currentPage * scholarshipsPerPage;
  const indexOfFirstScholarship = indexOfLastScholarship - scholarshipsPerPage;
  const currentScholarships = scholarshipInfo.slice(
    indexOfFirstScholarship,
    indexOfLastScholarship
  );

  // Manejadores de la paginación
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(scholarshipInfo.length / scholarshipsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(scholarshipInfo.length / scholarshipsPerPage);

  // Determinar el rango de páginas que se mostrarán
  let startPage = currentPage > 1 ? currentPage - 1 : 1;
  let endPage = currentPage + 1 <= totalPages ? currentPage + 1 : totalPages;

  if (endPage - startPage < 2) {
    endPage = startPage + 2 <= totalPages ? startPage + 2 : totalPages;
  }

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
    {(scholarshipGeneralPerms(idUR) || teamPerms) &&

      <div className="Student-Scholarship-Info">
      <div className="Top">
      <button className="Regresar" onClick={handleBackClick}>
      <img src={Arrow} alt="arrow" />
      <p>Regresar al menú</p>
      </button>
      <h1 className="Title_Becas">{currentTeam.name}</h1>
      <div className="Buttons">
      <div className="Notification" onClick={handleNotificationClick}>
      <BellFilled />
      {hasNewMessage && <span className="Alert"></span>}
      </div>
      <div className="Save" onClick={handleSave}>
      <SaveFilled /> <p>Guardar</p>
      </div>
      <NavLink className="Report" to={`./${params.id}/report`}>
      <BarChartOutlined /> <p>Generar Reporte</p>
      </NavLink>
      </div>
      </div>
      <div className="Container-Becas">
      {currentScholarships.map((scholarship) => (
        <StudentScholarshipCard
        key={scholarship.id_user}
        scholarship={scholarship}
        onChange={handleCardChange}
        idCycle={idCycle}
        idTeam={params.id}
        idEvaluation={idEvaluation}
        nameEvaluation={nameEvaluation}
        />
      ))}
      </div>
      <div className="Pagination">
      <button onClick={handlePrevPage} disabled={currentPage === 1}>
      <img src={Arrow} alt="Previous" />
      </button>
      {pageNumbers.map((number) => (
        <button
        key={number}
        onClick={() => handlePageClick(number)}
        className={currentPage === number ? "active" : ""}
        >
            {number}
          </button>
        ))}
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
        <img src={ArrowRight} alt="Next" />
        </button>
        </div>
        
        {showModal && (
          <div className="modal-overlay">
          <div className="modal">
          <h2>Cambios sin guardar</h2>
          <p>
          Tienes cambios sin guardar. ¿Seguro que deseas salir sin guardar?
          </p>
          <div className="modal-buttons">
          <button className="modal-confirm" onClick={confirmLeave}>
          Salir sin guardar
          </button>
          <button className="modal-cancel" onClick={cancelLeave}>
          Cancelar
          </button>
          </div>
          </div>
          </div>
        )}
        
        {showModalMessage && (
          <div className="modal-overlay">
          <div className="modal">
          <CloseOutlined
          className="modal-button"
          onClick={handleModalClose}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "flex-end",
          }}
          ></CloseOutlined>
          <h2>Mensaje: {scholarshipMessage}</h2>
          </div>
          </div>
        )}
        </div>
        }
        </>
      );
    };
    
    export default StudentScholarshipInfo;
    