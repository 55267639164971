import "./Emoji.css";
import React from "react";

export default function SadEmoji({ isAnimated, onClick }) {
  return (
    <svg
      className={`emoji sad-emoji ${isAnimated ? "animated" : ""}`}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 332 332"
      fill="none"
      onClick={onClick}
    >
      <g>
        <path
          d="M20.3632 107.312C30.9126 62.6813 65.6813 27.9126 110.312 17.3632C128.143 13.1486 146.863 10 163 10C179.137 10 197.857 13.1486 215.688 17.3632C260.319 27.9126 295.087 62.6813 305.637 107.312C309.851 125.143 313 143.863 313 160C313 176.137 309.851 194.857 305.637 212.688C295.087 257.319 260.319 292.087 215.688 302.637C197.857 306.851 179.137 310 163 310C146.863 310 128.143 306.851 110.312 302.637C65.6813 292.087 30.9126 257.319 20.3632 212.688C16.1486 194.857 13 176.137 13 160C13 143.863 16.1486 125.143 20.3632 107.312Z"
          fill="url(#paint0_linear_0_48)"
        />
        <path
          className={"emoji-head"}
          d="M20.3632 107.312C30.9126 62.6813 65.6813 27.9126 110.312 17.3632C128.143 13.1486 146.863 10 163 10C179.137 10 197.857 13.1486 215.688 17.3632C260.319 27.9126 295.087 62.6813 305.637 107.312C309.851 125.143 313 143.863 313 160C313 176.137 309.851 194.857 305.637 212.688C295.087 257.319 260.319 292.087 215.688 302.637C197.857 306.851 179.137 310 163 310C146.863 310 128.143 306.851 110.312 302.637C65.6813 292.087 30.9126 257.319 20.3632 212.688C16.1486 194.857 13 176.137 13 160C13 143.863 16.1486 125.143 20.3632 107.312Z"
          fill="#FFD400"
        />
      </g>

      {/* Face */}

      <g className={"emoji-face"}>
        <g className={"emoji-eyebrow left-eyebrow"} filter="url(#left-eyebrow)">
          <path
            d="M64.3573 22V22C53.973 34.576 38.2941 41.5572 21.9998 40.8602V40.8602"
            stroke="#1B1B1B"
            stroke-width="15.1515"
            stroke-linecap="round"
          />
        </g>
        <g
          className={"emoji-eyebrow right-eyebrow"}
          filter="url(#right-eyebrow)"
        >
          <path
            d="M168.813 22V22C179.197 34.576 194.876 41.5572 211.17 40.8602V40.8602"
            stroke="#1B1B1B"
            stroke-width="15.1515"
            stroke-linecap="round"
          />
        </g>
        <g className={"emoji-sclera right-sclera"} filter="url(#right-sclera)">
          <ellipse
            cx="65.4847"
            cy="92.2104"
            rx="45.4545"
            ry="45.4546"
            fill="#FFFEFA"
          />
        </g>
        <g className={"emoji-pupil right-pupil"} filter="url(#right-pupil)">
          <ellipse
            cx="69.7271"
            cy="92.8165"
            rx="30.303"
            ry="30.303"
            fill="#1B1B1B"
          />
        </g>
        <g className={"emoji-sclera left-sclera"} filter="url(#left-sclera)">
          <ellipse
            cx="167.303"
            cy="92.2104"
            rx="45.4545"
            ry="45.4546"
            fill="#FFFEFA"
          />
        </g>
        <g className={"emoji-pupil left-pupil"} filter="url(#left-pupil)">
          <ellipse
            cx="163.061"
            cy="92.8164"
            rx="30.303"
            ry="30.303"
            fill="#1B1B1B"
          />
        </g>
        <g className={"emoji-mouth"} filter="url(#mouth)">
          <path
            d="M77.6058 177.059V177.059C93.8099 143.427 141.705 143.427 157.909 177.059V177.059"
            stroke="#1B1B1B"
            stroke-width="22.7273"
            stroke-linecap="round"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_0_48"
          x1="163"
          y1="310"
          x2="163"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFD400" stop-opacity="1" />
          <stop offset="1" stop-opacity="0.5" stop-color="#0099FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
