import React, { useState, useEffect } from "react";
import { Checkbox, Col, Row, Select, Dropdown, message, Button } from "antd";
import {
  RightCircleFilled,
  FileTextOutlined,
  TeamOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "./FilterBar.css";
import server, { endpoints } from "../../utils/server";
const FilterBar = ({ id_cycle, onEvaluationChange }) => {
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [evaluationStyle, setEvaluationStyle] = useState(true);
  const [teamAndSelect, setTeamAndSelect] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedEvaluation, setSelectedEvaluation] = useState(0);
  const [teams, setTeams] = useState([]);
  const [teamsInfo, setTeamsInfo] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    getEvaluation();
    getTeamsInCycle();
  }, []);
  const getEvaluation = async () => {
    const params = {
      id_cycle,
    };
    const res = await server(
      endpoints.getEvaluation.route,
      endpoints.getEvaluation.method,
      params
    );
    const evaluations = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    setEvaluations(evaluations);
  };
  const getTeamsInCycle = async () => {
    const params = {
      id_cycle,
    };
    const res = await server(
      endpoints.getTeamsInCycle.route,
      endpoints.getTeamsInCycle.method,
      params
    );
    const teams = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    setTeams(teams);
  };
  const getUsersThatAnsweredEvaluationCount = async (id_evaluation) => {
    const params = {
      id_cycle,
      id_evaluation,
    };
    const res = await server(
      endpoints.getUsersThatAnsweredEvaluationCount.route,
      endpoints.getUsersThatAnsweredEvaluationCount.method,
      params
    );
    const userAnswered = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    var arraytemp = [];
    teamAndSelect.map((item) => {
      userAnswered.usersThatAnsweredCount.map((t) => {
        if (item.name == t.team) {
          arraytemp.push({
            id: item.id,
            name: item.name,
            selection: item.selection,
            users_answered: t.users_that_answerd,
            users_in_team: t.users_in_team,
          });
        }
      });
    });
    setTeamAndSelect(arraytemp);
    setTeamsInfo(userAnswered);
    handleEvaluationChange(selectedEvaluation, changeSelectedTeams(arraytemp));
  };

  const handleEvaluationChange = (newEvaluation, teamsChanged) => {
    onEvaluationChange(newEvaluation, teamsChanged);
  };
  const updateSelectedTeams = (i) => {
    var arraytemp = [];
    if (i >= 0) {
      teamAndSelect.map((item, index) => {
        index == i
          ? arraytemp.push({
              id: item.id,
              name: item.name,
              selection: !item.selection,
              users_answered: item.users_answered,
              users_in_team: item.users_in_team,
            })
          : arraytemp.push({
              id: item.id,
              name: item.name,
              selection: item.selection,
              users_answered: item.users_answered,
              users_in_team: item.users_in_team,
            });
      });
      setTeamAndSelect(arraytemp);
      arraytemp = [];
    } else {
      teams.map((item, index) => {
        arraytemp.push({
          id: item.id,
          name: item.name,
          selection: false,
          users_answered: 0,
          users_in_team: 0,
        });
      });
      setTeamAndSelect(arraytemp);
      arraytemp = [];
    }
  };
  const changeSelectedTeams = (arrayTeams) => {
    var arraytemp = [];
    arrayTeams.map((item, index) => {
      if (item.selection) {
        arraytemp.push(item);
      }
    });
    return arraytemp;
  };
  return (
    <div
      className={
        filtersVisible ? "Filters_container_compressed" : "Filters_container"
      }
    >
      <RightCircleFilled
        className="button_Filters"
        onClick={() => {
          setFiltersVisible(!filtersVisible);
          getEvaluation();
          updateSelectedTeams(-1);
        }}
        fill={"#000000"}
        style={{ color: "#ffffff" }}
      />
      <div className="containerBackground">
        {contextHolder}
        <nav className="menu">
          {filtersVisible ? (
            <>
              <ul>
                <li>
                  <TeamOutlined
                    style={{ color: "#ffffff", fontSize: "25px" }}
                  />
                </li>
                <li>
                  <FileTextOutlined
                    style={{
                      color: "#ffffff",
                      fontSize: "25px",
                      paddingTop: "70%",
                    }}
                  />
                </li>
              </ul>
            </>
          ) : (
            <>
              <ul classname="ShowedMenu">
                <h4 style={{ marginBottom: "10%" }}>
                  <TeamOutlined style={{ fontSize: "25px" }} />
                  Equipos
                </h4>
                <li className="Contains_Equipo">
                  {teamAndSelect.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          updateSelectedTeams(index);
                        }}
                      >
                        <Row
                          className={
                            item.selection
                              ? "Equipos_seleccionados"
                              : "Equipos_menu"
                          }
                        >
                          <div style={{ width: "80%" }}>{item.name}</div>
                          <div className="cuadradoEquipos">
                            {item.users_answered}/{item.users_in_team}
                          </div>
                        </Row>
                      </li>
                    );
                  })}
                </li>

                <li style={{ height: "160px", listStyle: "none" }}>
                  <h4
                    style={{ marginTop: "10%" }}
                    onClick={() => {
                      setEvaluationStyle(!evaluationStyle);
                    }}
                  >
                    <FileTextOutlined style={{ fontSize: "25px" }} />
                    Evaluaciones
                    {evaluationStyle ? (
                      <UpOutlined style={{ marginLeft: "20%" }} />
                    ) : (
                      <DownOutlined style={{ marginLeft: "20%" }} />
                    )}
                  </h4>

                  <ul
                    className={
                      evaluationStyle ? "hideEvaluations" : "showEvaluations"
                    }
                  >
                    {evaluations.map((item, index) => (
                      <li
                        onClick={() => {
                          setSelectedEvaluation(item.id);
                        }}
                        className={
                          selectedEvaluation === item.id
                            ? "Evaluacion_seleccionada"
                            : "containsEvaluation"
                        }
                      >
                        <h5>{item.name}</h5>
                      </li>
                    ))}
                  </ul>
                </li>

                <Button
                  type="primary"
                  style={{ marginLeft: "30%", marginTop: "45%" }}
                  onClick={() => {
                    getUsersThatAnsweredEvaluationCount(selectedEvaluation);
                  }}
                >
                  Aceptar
                </Button>
              </ul>
            </>
          )}
        </nav>
      </div>
    </div>
  );
};

export default FilterBar;
