import React, { useState } from "react";

import "./styles/evaluationsDataBase.css";
import FilterBar from "../components/EvaluationDB/FilterBar";
import TeamsDB from "../components/EvaluationDB/TeamsDB";
import MemebersDB from "../components/EvaluationDB/MembersDB";
import { Affix, Button, Divider } from "antd";

import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const EvaluaionsDataBase = ({ idCycle }) => {
  const [evaluationSelected, setEvaluationSelected] = useState(0);
  const [teamsSelected, setTeamsSelected] = useState([]);
  const [teamToShow, setTeamToShow] = useState();
  const handleEvaluationChange = (newevaluationSelected, selectedTeams) => {
    setEvaluationSelected(newevaluationSelected);
    setTeamsSelected(selectedTeams);
  };
  const handleTeamChange = (actualteam) => {
    setTeamToShow(actualteam);
  };

  return (
    <>
      <div class="Evaluation-DB-teamName">
        <div>
          <div>Evaluaciones</div>
        </div>
      </div>

      <div className="Evaluation-DB">
        <div className="Filters_Menu">
          <FilterBar
            id_cycle={idCycle}
            onEvaluationChange={handleEvaluationChange}
          ></FilterBar>
        </div>
        <div className="Buttons_DB_Fl">
          <Button type="primary" className="To-DB-Button">
            <NavLink to="/databaseTH"> Base de datos</NavLink>
          </Button>
        </div>

        <div className="Evaluation-DB-info">
          <TeamsDB team={teamToShow}></TeamsDB>

          <div className="Evaluation-DB-members">
            <MemebersDB
              id_cycle={idCycle}
              id_evaluation={evaluationSelected}
              allTeamsSelected={teamsSelected}
              onTeamSelectedChange={handleTeamChange}
            ></MemebersDB>
          </div>
        </div>
      </div>
    </>
  );
};

export default EvaluaionsDataBase;
