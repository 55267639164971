import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min.js";
import {} from "@ant-design/icons";
import server, { endpoints } from "../../utils/server.js";
import { rootEndpoint } from "../../utils/constants.js";
import "../styles/exposEvents.css";
import Flecha_derecha from  "../../resources/left-arrow.png";
import Modal from '../../components/Expos/modalExposEvents.jsx';
import search from "../../resources/search.png";

const ExposEvents = ({ idCycle, idUR }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [events, setEvents] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        getExpoEvent();
      }, [isModalOpen]);

    const getExpoEvent = async () => {
        const res = await server(
          endpoints.getExpoEvent.route,
          endpoints.getExpoEvent.method,
          { },
          { }
        );
        if (res.error_message) {
          alert(res.error_message);
        } else {
            setEvents(res.data);
          //message.success(res.message);
        }
      };

      const buscarItems = () => {
        var auxArray = [];
        auxArray = events.filter((item) => item.name.includes(searchValue));  
    
        if (searchValue != "") {
          setEvents(auxArray);
        } else {
          getExpoEvent();
        }
      };
    
      const handleSearch = (event) => {
        setSearchValue(event.target.value);
      };

      const handleKeyPress = (e) => {
        if (e.key === "Enter") {
          buscarItems();
        }
      };

      const openModal = () => {setIsModalOpen(true)};
      const closeModal = () => {setIsModalOpen(false)};
    return(
        <div className="exposEvents-container">
            <section className="exposEvents-principal">
                <header className="exposEvents-list-header">
                    <h3 className="exposEvents-list-header-title">
                        Mis Eventos
                    </h3>
                    <button className="exposEvents-list-header-button" onClick={openModal}>
                        + Crear Evento
                    </button>
                </header>
                <div  className="exposEvents-list-container">
                <div className="exposEvents-searchInside">
                    <input type="search" placeholder="Buscar Evento" 
                    className="exposEvents-list-search"
                    value={searchValue}
                onChange={(e) => {
                  handleSearch(e);
                }}
                onKeyPress={handleKeyPress}></input><img
                className="exposEvents-searchIcon"
                alt="searchIcon"
                src={search}
                onClick={() => {
                  buscarItems();
                }}
              />
              </div>
              <div className="exposEvents-list-item-container">
                
                    {events.length>0? events.map((i,index)=>{
                        return(
                          <NavLink to={`/exposEvents/${i.id}`} key={index} className="exposEvents-list-item-link">
                            <div className="exposEvents-list-item"> {i.name} <img src={Flecha_derecha} className="exposEvents-list-item-flecha" alt="flecha"></img></div>
                          </NavLink>
                        )
                    }):null}
                    </div>
                </div>
            </section>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
        
      </Modal>
        </div>


    );
}

export default ExposEvents;
