import axios from 'axios';
import { rootEndpoint } from './constants';

const event = new Event("remove", {"bubbles":true, "cancelable":false});
const passEvent = new Event("password", {"bubbles":true, "cancelable":false});


export const endpoints = {
    login: {
        route: "users/login",
        method: "post",
    },
    silentLogint: {
        route: "users/silentLogin",
        method: "post",
    },
    logout: {
        route: "users/logout",
        method: "post",
    },
    getSessionInfo: {
        route: "users/session",
        method: "get",
    },
    setPassword: {
        route: "users/password",
        method: "put",
    },
    getUserProfile: {
        route: 'users/profile',
        method: 'get',
    },
    updateUser: {
        route: 'users',
        method: 'put',
    },
    updateProfilePhoto: {
        route: 'users/profile/photo',
        method: 'put',
    },
    getUsersWithPerms: {
        route: 'users/permissions',
        method: 'get',
    },
    setPermissions: {
        route: 'users/permissions',
        method: 'put',
    },
    sendRestorePasswordEmail: {
        route: "users/restore-password/code",
        method: "put",
    },
    restorePassword: {
        route: 'users/restore-password',
        method: 'put',
    },
    sendQRcodeEmail: {
        route: 'users/qr',
        method: 'post',
    },
    uploadImageCard: {
        route: 'users/image_card',
        method: 'post',
    },
    helpTypes: {
        route: "users/help-types",
        method: "get",
    },
    insertHelpRequest: {
        route: "users/help",
        method: "post",
    },
    getCyclesWithTeams: {
        route: 'cycles/teams',
        method: 'get',
    },
    getTeamsInCycle: {
        route: 'teams/cycle',
        method: 'get',
    },
    getTeamAnswerDetails: {
        route: 'evaluations/answers/detail',
        method: 'get',
    },
    getEvaluationAnswers: {
        route: 'evaluations/answers',
        method: 'get',
    },
    getEvaluation: {
        route: 'evaluations',
        method: 'get',
    },
    getEvaluationQuestions: {
        route: 'evaluations/questions',
        method: 'get',
    },
    getEvaluationReport: {
        route: 'evaluations/answers/stats',
        method: 'get',
    },
    updateEvaluationQuestion: {
        route: 'evaluations/questions',
        method: 'put',
    },
    restartEvaluation: {
        route: 'evaluations/restart',
        method: 'put',
    },
    getPendingEvaluations: {
        route: "evaluations/pending",
        method: "get",
    },
    getAllUserEvaluationsReport: {
        route: "evaluations/user/team",
        method: "get",
    },
    deleteEvaluation: {
        route: "evaluations",
        method: "delete",
    },
    insertEvaluation: {
        route: "evaluations",
        method: "post",
    },
    updateEvaluation: {
        route: "evaluations",
        mehtod: "put",
    },
    insertEvaluationQuestion: {
        route: "evaluations/questions",
        mehtod: "post",
    },
    deleteEvaluationQuestion: {
        route: "evaluations/questions",
        method: "delete",
    },
    registerEvaluationAnswers: {
        route: "evaluations/answers",
        method: "post",
    },
    deleteOptionQueston: {
        route: "evaluations/questions/options",
        method: "delete",
    },
    insertCycle: {
        route: "cycles",
        method: "post",
    },
    changeCurrentCycle: {
        route: "cycles",
        method: "put",
    },
    getAllUsersInCycleWithTeams: {
        route: "cycles/users",
        method: "get"
    },
    getAllUsersInCycleExcel: {
        route: "cycles/users/excel",
        method: "get"
    },
    getUserTeams: {
        route: "tasks/users/teams",
        method: "get",
    },
    getUserTeamTasks: {
        route: "tasks/team",
        method: "post",
    },
    getOtherUserTeamTasks: {
        route: "tasks/team/user",
        method: "post",
    },
    getTaskParameters: {
        route: "tasks/tasks_parameters",
        method: "get",
    },
    registerTask: {
        route: "tasks",
        method: "post",
    },
    deleteTask: {
        route: "tasks",
        method: "delete",
    },
    editTaskCategories: {
        route: "tasks/task_categories",
        method: "post",
    },
    deleteTaskCategory: {
        route: "tasks/task_categories",
        method: "delete",
    },
    getTeamsOfUser: {
        route: "teams",
        method: "get",
    },
    getTeamUsers: {
        route: "teams/users",
        method: "get",
    },
    getUsersToInsert: {
        route: "teams/users/insert",
        method: "get",
    },
    getUserToInviteInfo: {
        route: "teams/user",
        method: "get",
    },
    insertUserToTeam: {
        route: "teams/users",
        method: "post",
    },
    deleteUserFromTeam: {
        route: "teams/deleteUser",
        method: "delete",
    },
    updateUserInTeam: {
        route: "teams/users",
        method: "put",
    },
    insertTeam: {
        route: "teams",
        method: "post",
    },
    getTeamPermOptions: {
        route: "teams/permissions",
        method: "get",
    },
    deleteTeamFromCycle: {
        route: "teams/deleteTeam",
        method: "delete",
    },
    getTeamsInCyle: {
        route: "teams/cycle",
        method: "get",
    },
    updateTeam: {
        route: "teams",
        method: "put",
    },
    getOtherCyclesTeams: {
        route: "teams/not/cycle",
        method: "get",
    },
    getTeamReport: {
        route: "teams/report",
        method: "get",
    },
    getNotUserTeams: {
        route: "teams/notuser",
        method: "get",
    },
    switchUserOfTeam: {
        route: "teams/user",
        method: "put",
    },
    getDashboardInfo: {
        route: "dashboard",
        method: "post",
    },
    editDashboardItem: {
        route: "dashboard/items",
        method: "post",
    },
    deleteItemFromCategory: {
        route: "dashboard/items",
        method: "delete",
    },
    getCareerOptions: {
        route: "userInfo/careers",
        method: "get",
    },
    getHighSchoolOptions: {
        route: "userInfo/highschools",
        method: "get",
    },
    insertHighschool: {
        route: "userInfo/highschools",
        method: "post",
    },
    getRoles: {
        route: "userInfo/roles",
        method: "get",
    },
    getAllEvents: {
        route: "reports",
        method: "get",
    },
    updateReport: {
        route: "reports",
        method: "put",
    },
    insertEventReport: {
        route: "reports",
        method: "post",
    },
    deleteEventReport: {
        route: "reports",
        method: "delete"
    },
    getEvents: {
        route: "events",
        method: "get",
    },
    registerEvents: {
        route: "events",
        method: "post",
    },
    deleteEvents: {
        route: "events",
        method: "delete",
    },
    getDasboardInfo: {
        route: "dashboard",
        method: "post"
    },
    editDashboardItem: {
        route: "dashboard/items",
        method: "post"
    },
    deleteItemFromCategory: {
        route: "dashboard/items",
        method: "delete"
    },
    editDashboardCategory: {
        route: "dashboard/categories",
        method: "post"
    },
    deleteDashboardCategory: {
        route: "dashboard/categories",
        method: "delete"
    },
    updateShirtStatus: {
        route: "users/shirt",
        method: "put"
    }, 
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getWorkshopUsers: {
        route: "workshop/users",
        method: "get"
    },
    getWorkshopSessions: {
        route: "workshop/sessions",
        method: "get"
    },
    postWorkshopSessions: {
        route: "workshop/sessions",
        method: "post"
    },
    updateWorkshopSessions: {
        route: "workshop/sessions",
        method: "put"
    },
    deleteWorkshopSessions: {
        route: "workshop/sessions",
        method: "delete"
    },
    getWorkshop: {
        route: "workshop",
        method: "get"
    },
    insertWorkshop: {
        route: "workshop",
        method: "post"
    },
    getUserInWorkshopPerCycle: {
        route: "workshop/users/workshop",
        method: "get"
    },
    insertWorkshopInCycle:{
        route: "workshop/cycles",
        method: "post"
    },
    getWorkshopInCycle:{
        route: "workshop/cycles",
        method: "get"
    },
    insertUserPerWorkshopPerCycle:{
        route: "workshop/users",
        method: "post"
    },
    getUsersOfOtherWorkshopPerCycle:{
        route: "workshop/users/otherWorkshop",
        method: "get"
    },
    getAttendance:{
        route: "workshop/attendance",
        method: "get"
    },
    updateAttendance:{
        route: "workshop/attendance",
        method: "put",
    },
    insertWorkshopsSessions:{
        route: "workshop/sessions",
        method: "post"
    },
    getWorkshopOfOtherCycle:{
        route:"workshop/otherCycles",
        method:"get",
    },
    deleteWorkshopInCycle:{
        route:"workshop/cycles",
        method:"delete"
    },
    deleteUserInWorkshopPerCycle:{
        route: "workshop/users",
        method: "delete",
    }, 
    putSessionPhoto: {
        route: "workshop/session/photo",
        method: "put",
    },
    getUsersThatAnsweredEvaluationCount: {
        route: "evaluations/answers/count",
        method: "get",
    },
    getEvaluationsUsersThatAnswered: {
        route: "evaluations/users-answered",
        method: "get",
    },
    getAllScholarshipInfo: {
        route: "scholarship/review",
        method: "get",
    },
    userInTeams: {
        route: "scholarship/teamsOfUser",
        method: "get",
    },


    ///////////////////////////////////////////////////////////////////////////////////////
    sendMassMail: {
        route: "users/send_mail",
        method: "post",
    },
    ///////////////////////////////////////////////////////////////////////////////////////
    getScholarship: {
        route: "scholarship/users",
        method: "get",
    },
    getProfesionalTeams: {
        route: "scholarship/teams",
        method: "get",
    },
    getScholarshipData: {
        route: "scholarship/Data",
        method: "get"
    },
    getTeamReview: {
        route: "scholarship/teamReview",
        method: "get",
    },
    insertScholarship: {
        route: "scholarship/users",
        method: "post",
    },
    getScholarshipMessage: {
        route: "scholarship/message",
        method: "get",
    },
    getScholarshipBudget: {
        route: "scholarship/budget",
        method: "get",   
    },
    getTeamsCost: {
        route: "scholarship/teamsCost",
        method: "get",
    },
    insertScholarshipBudget: {
        route: "scholarship/budget",
        method: "post",
    },
    updateScholarshipApproval: {
        route: "scholarship/approval",
        method: "put",
    },
    updateScholarshipType: {
        route: "scholarship/type",
        method: "put",
    },
    insertScholarshipMessage: {
        route: "scholarship/message",
        method: "post",
    },
    getScholarshipExcel:{
        route: "scholarship/excel",
        method: "get", 
    },
    getScholarshipExcel:{
        route: "scholarship/excel",
        method: "get", 
    },
    getScholarshipBudget: {
        route: "scholarship/budget",
        method: "get",   
    },
    getTeamsCost: {
        route: "scholarship/teamsCost",
        method: "get",
    },
    insertScholarshipBudget: {
        route: "scholarship/budget",
        method: "post",
    },
    updateScholarshipApproval: {
        route: "scholarship/approval",
        method: "put",
    },
    updateScholarshipType: {
        route: "scholarship/type",
        method: "put",
    },
    insertScholarshipMessage: {
        route: "scholarship/message",
        method: "post",
    },
    getLastUserComments: {
        route: "scholarship/comments",
        method: "get",
    },
    getScholarshipData: {
        route: "scholarship/data",
        method: "get",
    },
    insertUserComments: {
        route: "scholarship/comments",
        method: "post",
    },
    ////////////////////////////////////////////
    getExpoEventData: {
        route: "expoEvent/data",
        method: "get"
    },
    insertExpoEventData: {
        route: "expoEvent/data/",
        method: "post",
    },
    getExpoEvent:{
        route: "expoEvent",
        method: "get",
    },
    insertExpoEvent:{
        route: "expoEvent",
        method: "post",
    },
    updateExpoEvent: {
        route: "expoEvent/",
        method: "put",
    },
};



const server = async (endpoint, method, params = {}, body = {}) => {
    let urlParams = [];
    Object.keys(params).forEach(param => {
        urlParams.push([param, params[param]]);
    });
    params = new URLSearchParams(urlParams);
    const auth = localStorage.getItem("auth");
    if (method === "get" || method === "delete"){
        const res = await axios[method](`${rootEndpoint()}api/${endpoint}`, { params, headers: { authorization: auth }  });
        if (res.data.error_message.includes("Unauthorized")) {
            document.dispatchEvent(event);
            return { error_message: res.data.error_message.split("|")[1] }
        }
        if (res.data.error_message.includes("Password|")) {
            document.dispatchEvent(passEvent);
            return { error_message: res.data.error_message.split("|")[1] }
        }
        return res.data;
    } else {
        const res = await axios[method](`${rootEndpoint()}api/${endpoint}`, body, { params, headers: { authorization: auth } });
        if (res.data.error_message.includes("Unauthorized")) {
            document.dispatchEvent(event);
            return { error_message: res.data.error_message.split("|")[1] }
        }
        if (res.data.error_message.includes("Password|")) {
            document.dispatchEvent(passEvent);
            return { error_message: res.data.error_message.split("|")[1] }
        }
        
        return res.data;
    }
};

export default server;
