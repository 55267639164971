import React, { useState, useEffect } from "react";
import "./style/Desgloce_Becas.css";
import server, { endpoints } from "../../utils/server";

const DesgloceModulo = ({ idCycle,muestraId,collegue_percentage,inscription_percentage, oficialPercentage, changeDesgloce }) => {
  const [userScolarship, setUserScolarship] = useState([]);

  useEffect(() => {
    userInTeams();
  }, [muestraId]);

  const userInTeams = async () => {
    const res = await server(
      endpoints.userInTeams.route,
      endpoints.userInTeams.method,
      { id_cycle: 0, id_user: muestraId }
    );
    if (res.error_message) {
    } else {
      setUserScolarship(res.data.scholarship);
    }
  };

  return (
    <div className="Desgloce_container">
      <h2>Desglose de Becas</h2>
      {/* <p className="ID">{userScolarship.id_user}</p>*/}
      {userScolarship != null
        ? userScolarship.map((i, index) => {
            return (
              <div className="Equipo_container" key={index}>
                <h3>{i.Team}</h3>
                <div>
                  <div className="Becas_description">
                    Beca Inscripción:
                    <div className="Porcentajes_container">
                      {i.inscription_percentage}%
                    </div>
                  </div>
                  <div className="Becas_description">
                    Beca Colegiatura:
                    <div className="Porcentajes_container">
                      {i.collegue_percentage}%
                    </div>
                  </div>
                  <div className="Porcentajes_suma">{oficialPercentage}%</div>
                  <hr className="Borde_Beca"></hr>
                </div>
              </div>
            );
          })
        : null}
      <div className="Total_container">
        <h4>Total AyC</h4>
        <div className="Total_Becas">
          <div className="Beca_suma">
            Beca Inscripción:
            <div style={{ color: "#667085" }}>{inscription_percentage}%</div>
          </div>
          <div className="Beca_suma">
            Beca Colegiatura:
            <div style={{ color: "#667085" }}>{collegue_percentage}%</div>
          </div>
          <div className="Beca_suma">
            Beca Total AyC:
            <div style={{ color: "#667085" }}>{oficialPercentage}%</div>
          </div>
        </div>
      </div>
      <button className="Button_Cerrar" onClick={() => changeDesgloce(false)}>
        Cerrar
      </button>
    </div>
  );
};

export default DesgloceModulo;
