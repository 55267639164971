import React, { useState, useEffect } from "react";
import { message } from "antd";

import {} from "@ant-design/icons";
import DesgloceModulo from "../Becas/Desgloce_Becas.jsx";
import Flecha_abajo from "../../resources/Flecha_abajo.png";
import tres_puntos from "../../resources/raya_bordeada.png";
import search from "../../resources/search.png";
import close_icon from "../../resources/Close_icon.png";
import server, { endpoints } from "../../utils/server";
import { rootEndpoint } from "../../utils/constants.js";
import tres_puntos_grises from "../../resources/puntos_grises.png";
import "./style/Table_Becas.css";

const TableBecas = ({ idCycle, exportarTabla, idUR }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [mostrarBorrar, setMostrarBorrar] = useState(false);
  const [mostrarBuscar, setMostrarBuscar] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [typeSearch, setTypeSearch] = useState("");
  const [usersExcel, setUsersExcel] = useState([]);
  const [datosTabla, setDatosTabla] = useState([]);
  const [datosTablaFilter, setDatosTablaFilter] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [comentariesEditable, setComentariesEditable] = useState(false);
  const [comentariesVisible, setComentariesVisible] = useState(null);
  const [comentariesUser, setComentariesUser] = useState();
  const [comentariesToClose, setComentariesToClose] = useState(false);
  const [actualComentarie, setActualComentarie] = useState("");
  const [teamsUser, setTeamsUser] = useState([]);
  const [checkedFilter, setCheckedFilter] = useState(true);
  const [userTotalScholarship, setUserTotalScholarship] = useState();
  const [userInscriptionScholarship, setUserInscriptionScholarship] =
    useState();
  const [userCollegueScholarship, setUserCollegueScholarship] = useState();
  const [scholarshipStatus, setScholarshipStatus] = useState([]);
  const [scholarshipType, setScholarshipType] = useState([]);
  const [mostrarDesgloce, setMostrarDesgloce] = useState(false);

  useEffect(() => {
    getScholarshipData();
    getAllScholarshipInfo();
  }, []);

  useEffect(() => {
    datosTabla.map((i, index) => {
      if (i.checked == true) {
        getScholarshipExcel();
      }
    });
  }, [exportarTabla]);

  const getAllScholarshipInfo = async () => {
    const res = await server(
      endpoints.getAllScholarshipInfo.route,
      endpoints.getAllScholarshipInfo.method,
      { id_cycle: 0 }
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {

      const data = res.data.scholarship
      data.map((i)=>{
        i.checked = false;
        i.comentariesShow = false
      })
      setDatosTabla(data);
      setDatosTablaFilter(data);
    }
  };

  const getTeamsOfUser = async (idUser) => {
    const res = await server(
      endpoints.getTeamsOfUser.route,
      endpoints.getTeamsOfUser.method,
      { id: idUser, id_cycle: idCycle }
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {
      return res.data.userTeams;
    }
  };

  const getScholarshipData = async () => {
    const res = await server(
      endpoints.getScholarshipData.route,
      endpoints.getScholarshipData.method
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {
      setScholarshipStatus(res.data.scholarshipStatus);
      setScholarshipType(res.data.scholarshipType);
    }
  };

  const getLastUserComments = async (idUser) => {
    const team_id = await getTeamsOfUser(idUser);
    const aux = team_id[0].id;
    console.log(aux);

    const res = await server(
      endpoints.getLastUserComments.route,
      endpoints.getLastUserComments.method,
      { id_cycle: idCycle, id_user: idUser, id_team: aux }
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {
      console.log(res.data.comments);
      res.data.comments.map((i, index) => {
        setComentariesUser(comentariesUser + " " + i.comments);
      });
      //;
    }
  };

  const insertUserComments = async (idUser, team) => {
    console.log("user:" + idUser);
    console.log("team:" + team);
    console.log("comments:" + actualComentarie);
    const res = await server(
      endpoints.insertUserComments.route,
      endpoints.insertUserComments.method,
      { id_cycle: idCycle, id_user: idUser, id_team: team },
      { comments: actualComentarie }
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {
      message.success("Se logro :D");
      setActualComentarie("");

      //;
    }
  };

  const getScholarshipExcel = async () => {
    const queryString = new URLSearchParams({
      idUR: idUR,
      id_cycle: 0,
    }).toString();
    var url = ``;

    url = `${rootEndpoint()}api/${
      endpoints.getScholarshipExcel.route
    }?${queryString}`;
    datosTabla.map((i, index) => {
      if (i.checked) {
        url += `&id_users=${i.id_user}`;
      }
    });

    try {
      window.location.href = url;
    } catch (error) {
      console.error("Error al obtener la URL de Excel:", error);
      return null;
    }
  };

  const changeDesgloce = (estado) => {
    setMostrarDesgloce(estado);
  };

  const buscarItems = (value = "") => {
    var auxArray = [];
    switch (typeSearch) {
      case "ID":
        auxArray = datosTabla.filter((item) => item.id.toLowerCase().includes(value.toLowerCase()));
        break;
      case "NOMBRE":
        auxArray = datosTabla.filter((item) =>
          item.first_name.toLowerCase().includes(value.toLowerCase())
        );
        break;
      case "APELLIDO":
        auxArray = datosTabla.filter((item) =>
          item.last_name.toLowerCase().includes(value.toLowerCase())
        );
        break;
    }

    if (value != "") {
      setDatosTablaFilter(auxArray);
    } else {
      setDatosTablaFilter(datosTabla)
    }
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    buscarItems(event.target.value)
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      buscarItems();
    }
  };
  const handleCheckboxChange = (index) => {
    const auxarray = [];
    datosTablaFilter[index].checked = !datosTablaFilter[index].checked;
    /*datosTabla.map((i) => {
      if (i.checked == true) {
        auxarray.push(i);
      }
    });*/
    setDatosTabla(datosTabla);
    setUsersExcel(auxarray);
  };
  const handleAllChecked = () => {
    if (checkedFilter) {
      datosTabla.map((i, index) => {
        i.checked = true;
      });
    } else {
      datosTabla.map((i, index) => {
        i.checked = false;
      });
    }
    setDatosTabla(datosTabla);
  };

  const handleShowComentaries = (index, i) => {
    return (
      <tr class="ComentariesLine">
        <td colSpan="15">
          {comentariesUser != "undefined"
            ? "No hay comentarios"
            : comentariesUser}
        </td>
      </tr>
    );
  };

  const handleStatus = (id) => {
    var aux = "";
    scholarshipStatus.map((i, index) => {
      if (i.id == id) {
        aux = i.type;
      }
    });
    return aux;
  };

  const handleType = (id) => {
    var aux = "";
    scholarshipType.map((i, index) => {
      if (i.id == id) {
        aux = i.type;
      }
    });
    return aux;
  };

  const changeStatusClassname = (id) => {
    switch (handleStatus(id)) {
      case "Activo":
        return "Activo";
      case "Sin beca":
        return "Sin_beca";
      case "Baja":
        return "Baja";
      case "Nuevo":
        return "Nuevo";
    }
  };
  return (
    <div>
      {contextHolder}

      {mostrarDesgloce ? (
        <div className="Desglozar">
          <DesgloceModulo
            idCycle={idCycle}
            muestraId={selectedId}
            collegue_percentage={userCollegueScholarship}
            inscription_percentage={userInscriptionScholarship}
            oficialPercentage={userTotalScholarship}
            changeDesgloce={setMostrarDesgloce}
          ></DesgloceModulo>
        </div>
      ) : null}

      {mostrarBuscar ? (
        <div className="searchComponent">
          <div className="upperSearch">
            <h5>Search</h5>
            <img
              className="closeIcon"
              alt="closeIcon"
              src={close_icon}
              onClick={() => {
                setMostrarBuscar(false);
                buscarItems(searchValue)
              }}
            /> 
          </div>
          <div className="searchInside">
            <div className="inputContainer">
              <input
                type="search"
                className="searchInput"
                value={searchValue}
                onChange={(e) => {
                  handleSearch(e);
                  setMostrarBorrar(true);
                }}
                onKeyPress={handleKeyPress}
              />
              {/*<img
                className="trashIcon"
                src={trash_can}
                onClick={() => {
                  setSearchValue("");
                }}
              />*/}
            </div>
            {mostrarBorrar ? (
              <img
                className="deleteIcon"
                alt="deleteIcon"
                src={close_icon}
                onClick={() => {
                  setSearchValue("");
                  setMostrarBorrar(false);
                  buscarItems("");
                }}
              />
            ) : (
              <img
                className="searchIcon"
                alt="searchIcon"
                src={search}
                onClick={() => {
                  buscarItems(searchValue);
                }}
              />
            )}
          </div>
          <button
            className="cleanSearch"
            onClick={() => {
              buscarItems(searchValue);
            }}
          >
            Buscar
          </button>
        </div>
      ) : null}
      
      <table className="Table_datos" id="TablaDeBecas">
        <tbody>
          <tr>
            <td className="Columna_icono">
              <div
                className="trespuntos_container"
                onClick={() => {
                  setCheckedFilter(!checkedFilter);
                  handleAllChecked();
                }}
              >
                <img
                  className={"trespuntos"}
                  src={tres_puntos}
                  alt="tres_puntos"
                />
              </div>
            </td>
            <td className="Columna_id">
              ID{" "}
              <img
                className={"Flecha_abajo_icon"}
                alt="Flecha_abajo_icon"
                src={Flecha_abajo}
                onClick={() => {
                  setMostrarBuscar(!mostrarBuscar);
                  setTypeSearch("ID");
                }}
              />
            </td>
            <td className="Columna_apellido">
              Apellido
              <img
                className={"Flecha_abajo_icon"}
                alt="Flecha_abajo_icon"
                src={Flecha_abajo}
                onClick={() => {
                  setMostrarBuscar(!mostrarBuscar);
                  setTypeSearch("APELLIDO");
                }}
              />
            </td>
            <td className="Columna_nombre">
              Nombre
              <img
                className={"Flecha_abajo_icon"}
                alt="Flecha_abajo_icon"
                src={Flecha_abajo}
                onClick={() => {
                  setMostrarBuscar(!mostrarBuscar);
                  setTypeSearch("NOMBRE");
                }}
              />
            </td>
            <td className="Columna_carrera">Carrera</td>
            <td className="Columna_semestre">Semestre</td>
            <td className="Columna_verano">Verano-Invierno</td>
            <td className="Columna_verano">Idioma</td>
            <td className="Columna_inscripcion">B.Inscripción</td>
            <td className="Columna_colegiatura">B.Colegiatura</td>
            <td className="Columna_ayc">Beca AyC</td>

            <td className="Columna_estatus">Estatus</td>
            <td className="Columna_equipos">Equipos</td>
            <td className="Columna_tb">Tipo de Beca</td>
            {/* <td className="Columna_comentarios">Comentarios</td>
            <td className="Columna_opciones"></td> */}
          </tr>
          {datosTabla != null && datosTabla.length>0
            ? datosTablaFilter.map((i, index) => {
                return (
                  <>
                    <tr className="Informacion_columnas">
                      <td className="Informacion_icono">
                        <input
                          type="checkbox"
                          checked={i.checked}
                          id={"cbox" + String(index)}
                          onClick={() => {
                            handleCheckboxChange(index);
                          }}
                        />
                      </td>
                      <td className="Informacion_id">{i.id}</td>
                      <td className="Informacion_apellido">{i.last_name}</td>
                      <td className="Informacion_nombre">{i.first_name}</td>
                      <td className="Informacion_carrera">{i.initials}</td>
                      <td className="Informacion_semestre">{i.semester}</td>
                      <td className="Informacion_verano">
                        {i.summer ? "Aplica" : "No aplica"}
                      </td>
                      <td className="Informacion_verano">
                        {i.language ? "Aplica" : "No aplica"}
                      </td>
                      <td className="Informacion_inscripcion">
                        {i.inscription_percentage}
                      </td>
                      <td className="Informacion_colegiatura">
                        {i.collegue_percentage}
                      </td>
                      <td className="Informacion_ayc">{i.totalScholarship}</td>

                      <td className="Informacion_estatus">
                        <li className={changeStatusClassname(i.id_status)}>
                          {handleStatus(i.id_status)}
                        </li>
                      </td>

                      <td
                        className="Informacion_equipos"
                        onClick={() => {
                          setSelectedId(i.id_user);
                          setUserInscriptionScholarship(
                            i.inscription_percentage
                          );  
                          setUserCollegueScholarship(i.collegue_percentage);
                          setUserTotalScholarship(i.totalScholarship);
                          setMostrarDesgloce(!mostrarDesgloce);
                        }}
                      >
                        <li
                          className={
                            i.teams != null ? "Team_true" : "Team_false"
                          }
                        >
                          {i.teams != null
                            ? i.teams.length > 1
                              ? `${i.teams[0]?.name} +${i.teams.length - 1}`
                              : i.teams[0]?.name
                            : "Baja"}
                        </li>
                      </td>
                      <td className="Informacion_tb">
                        {handleType(i.id_scholarship_type)}
                      </td>
                      {/* <td className="Informacion_comentarios">
                        <div
                          className="Comentarios"
                          onClick={() => {
                            setComentariesEditable(true);
                            setComentariesToClose(!comentariesToClose);
                            setComentariesVisible(i.id_user);
                          }}
                        >
                          + Comentarios
                        </div>
                      </td>
                      <td
                        className="Informacion_opciones"
                        onClick={() => {
                          setComentariesEditable(false);
                          setComentariesUser("");
                          getLastUserComments(i.id_user, i.teams);
                          setComentariesToClose(!comentariesToClose);
                          setComentariesVisible(i.id_user);
                        }}
                      >
                        <img
                          className={"puntos_opciones"}
                          alt="puntos_opciones"
                          src={tres_puntos_grises}
                        />
                      </td> */}
                    </tr>
                    {comentariesVisible === i.id_user && comentariesToClose
                      ? handleShowComentaries(i.id_user, i.teams)
                      : null}
                  </>
                );
              })
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default TableBecas;
