import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Col,
  Row,
  Select,
  Input,
  message,
  Upload,
  Space,
  Tooltip,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  MailOutlined,
  PaperClipOutlined,
  UserOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import server, { endpoints } from "../utils/server";
const { Option } = Select;

const StickyButton = ({ idCycle }) => {
  const [messages, setMessages] = useState("");
  const [subject, setSubject] = useState("");
  const [fileName, setFileName] = useState();
  const [convertedFile, setConvertedFile] = useState();
  const [isMailModalOpen, setIsMailModalOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRolesArray, setSelectedRolesArray] = useState([]);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    try {
      const res = await server(
        endpoints.getRoles.route,
        endpoints.getRoles.method
      );
      setRoles(res.data);
    } catch (err) {
      message.error(err);
    }
  };

  const sendMassMail = async () => {
    const res = await server(
      endpoints.sendMassMail.route,
      endpoints.sendMassMail.method,
      { id_cycle: idCycle },
      {
        role: selectedRolesArray,
        subject: subject,
        message: messages,
        fileName: fileName || null,
        fileContents: convertedFile || null,
      }
    );
    if (res.error_message) {
      message.error(res.error_message);
      return;
    } else if (res.message) {
      message.success(res.message);
      return;
    }
  };

  const showMailModal = () => {
    setIsMailModalOpen(true);
  };
  const handleOkMailModal = () => {
    setIsMailModalOpen(false);
    setMessages("");
    setSubject("");
  };
  const handleCancelMailModal = () => {
    setIsMailModalOpen(false);
    setMessages("");
    setSubject("");
  };
  const handleMessageOnChange = (e) => {
    setMessages(e.target.value);
  };
  const handleSubjectOnChange = (e) => {
    setSubject(e.target.value);
  };

  const nullifyFile = () => {
    setConvertedFile(null);
    setFileName(null);
  };

  const onRoleChange = (value) => {
    if (value.includes("0") && !selectedRolesArray.includes("0")) {
      setSelectedRolesArray(["0"]);
    } else if (!value.includes("0") && selectedRolesArray.includes("0")) {
      setSelectedRolesArray(value);
    } else if (value.includes("0") && selectedRolesArray.includes("0")) {
      const newValue = value.filter((item) => item !== "0");
      setSelectedRolesArray(newValue);
    } else {
      setSelectedRolesArray(value);
    }
  };

  const encodeFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });

  const beforeUpload = async (file) => {
    setFileName(file.name);
    let converted_file = await encodeFile(file);
    setConvertedFile(converted_file);
  };

  return (
    <>
      <Button
        type="primary"
        shape="circle"
        size="large"
        icon={<MailOutlined />}
        onClick={showMailModal}
      />
      <Modal
        width={700}
        title="Enviar correo de aviso"
        open={isMailModalOpen}
        onOk={handleOkMailModal}
        onCancel={handleCancelMailModal}
      >
        <Row>
          <Col span={14}>
            <Row>
              <h3 style={{ marginRight: "70%" }}>Mensaje</h3>
              {!fileName ? "" : ""}
            </Row>
            <TextArea
              height={800}
              autoSize={{ minRows: 4, maxRows: 4 }}
              value={messages}
              onChange={handleMessageOnChange}
            />
            {fileName ? (
              <Row>
                <Space style={{ gap: "30px" }}>
                  Archivo a enviar:
                  <div>{fileName ? " " + fileName : ""}</div>
                  <Upload
                    accept="image/*"
                    multiple={false}
                    showUploadList={false}
                    beforeUpload={(e) => beforeUpload(e)}
                  >
                    <Tooltip title={"Editar la imagen"}>
                      <Button
                        icon={<EditOutlined />}
                        size="small"
                        shape="round"
                      />
                    </Tooltip>
                  </Upload>
                  <Tooltip title={"Quitar la imagen"}>
                    <DeleteOutlined onClick={nullifyFile} />
                  </Tooltip>
                </Space>
              </Row>
            ) : (
              <div
                align="flex-end"
                style={{
                  display: "flex",
                  position: "fixed",
                }}
              >
                <Upload
                  accept="image/*"
                  multiple={false}
                  showUploadList={false}
                  beforeUpload={(e) => beforeUpload(e)}
                >
                  <Tooltip title={"Agregar una imagen"}>
                    <Button
                      icon={<PaperClipOutlined />}
                      size="small"
                      shape="round"
                    />
                  </Tooltip>
                </Upload>
              </div>
            )}
          </Col>
          <Col span={9} offset={1}>
            <Row>
              <UserOutlined />
              <h3 style={{ paddingLeft: "2%" }}>Tipo de Usuario</h3>
            </Row>
            <Row>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                value={selectedRolesArray}
                onChange={onRoleChange}
              >
                <Option key={0} name={"Todos"}>
                  Todos
                </Option>
                {roles.map((t) => {
                  return (
                    <Option key={t.id} name={t.name}>
                      {t.name}
                    </Option>
                  );
                })}
              </Select>
            </Row>
            <Row>
              <h3>Asunto: </h3>
              <Input
                onChange={handleSubjectOnChange}
                placeholder="Asunto"
                value={subject}
              />
            </Row>
            <Row>
              <Button
                size="big"
                shape="round"
                type="primary"
                onClick={sendMassMail}
              >
                Enviar
              </Button>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default StickyButton;
