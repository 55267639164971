import React, { useState, useEffect } from "react";
import { message } from "antd";
import ScholarshipCard from "../components/scholarshipCard";
import { Typography, Row } from "antd";
import server, { endpoints } from "../utils/server";
const { Title } = Typography;

const Scholarships = ({idCycle, idUR}) => {
  useEffect(() => {
    getProfesionalTeams(idCycle);
  }, []);

  const getProfesionalTeams = async (idCycle) => {
    const res = await server(
      endpoints.getProfesionalTeams.route,
      endpoints.getProfesionalTeams.method,
      { id_cycle: idCycle }
    );

    if (res.error_message) {
      message.error(res.error_message);
      return;
    } else {
      setTeamsArray(res.data);
    }
  };

  const [teamsArray, setTeamsArray] = useState([]);

  return (
    <div style={{overflowX: "hidden"}}>
      <Title
        level={2}
        type="danger"
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: "20px",
        }}
      >
        Becas Educativas
      </Title>
      <Row
        style={{
          paddingTop: "20px",
          paddingInline: "10%",
          display: "flex",
          flexWrap: "wrap",
          flex: 1,
        }}
        justify="space-around"
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        {teamsArray.map((t, index) => {
          return (
            <>
              <ScholarshipCard
                idUR={idUR}
                id={t.Id_team}
                team={t.Team}
                membersInTeam={t.Count}
                colorNumber={index}
                buttonAvailability={t.is_profesional}
              />
            </>
          );
        })}
      </Row>
    </div>
  );
};

export default Scholarships;
