import "./Emoji.css";
import React from "react";

export default function HappyEmoji({ isAnimated, onClick }) {
  return (
    <svg
      className={`emoji happy-emoji ${isAnimated ? "animated" : ""}`}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 332 332"
      fill="none"
      onClick={onClick}
    >
      <path
        className={"emoji-head"}
        d="M20.3632 107.312C30.9126 62.6813 65.6813 27.9126 110.312 17.3632C128.143 13.1486 146.863 10 163 10C179.137 10 197.857 13.1486 215.688 17.3632C260.319 27.9126 295.087 62.6813 305.637 107.312C309.851 125.143 313 143.863 313 160C313 176.137 309.851 194.857 305.637 212.688C295.087 257.319 260.319 292.087 215.688 302.637C197.857 306.851 179.137 310 163 310C146.863 310 128.143 306.851 110.312 302.637C65.6813 292.087 30.9126 257.319 20.3632 212.688C16.1486 194.857 13 176.137 13 160C13 143.863 16.1486 125.143 20.3632 107.312Z"
        fill="#FFD400"
      />

      {/* Face */}
      <g className={"emoji-face"}>
        {/* Mouth */}
        <g className={"emoji-mouth"} filter="url(#filter7_dii_0_20)">
          <path
            d="M63.9922 126.727V126.727C80.1963 160.359 128.091 160.359 144.295 126.727V126.727"
            stroke="#1B1B1B"
            stroke-width="22.7273"
            stroke-linecap="round"
          />
        </g>

        {/* Left Eye */}
        <g className={"emoji-eye left-eye"}>
          {/* Sclera */}
          <g
            className={"emoji-sclera right-sclera"}
            filter="url(#filter0_di_0_20)"
          >
            <ellipse
              cx="52.4849"
              cy="49.697"
              rx="45.4545"
              ry="45.4546"
              fill="#FFFEFA"
            />
          </g>

          {/* Pupil */}
          <g
            className={"emoji-pupil right-pupil"}
            filter="url(#filter1_dii_0_20)"
          >
            <ellipse
              cx="56.7273"
              cy="50.303"
              rx="30.303"
              ry="30.303"
              fill="#1B1B1B"
            />
          </g>

          {/* Bright */}
          <g
            className={"emoji-bright right-bright"}
            filter="url(#filter2_di_0_20)"
          >
            <circle cx="66.0001" cy="37.5" r="10" fill="#FFFEFA" />
          </g>
        </g>

        {/* Right Eye */}
        <g className={"emoji-eye right-eye"}>
          {/* Sclera */}
          <g
            className={"emoji-sclera right-sclera"}
            filter="url(#filter3_di_0_20)"
          >
            <ellipse
              cx="154.303"
              cy="49.697"
              rx="45.4545"
              ry="45.4546"
              fill="#FFFEFA"
            />
          </g>

          {/* Pupil */}
          <g
            className={"emoji-pupil right-pupil"}
            filter="url(#filter5_dii_0_20)"
          >
            <ellipse
              cx="150.061"
              cy="50.303"
              rx="30.303"
              ry="30.303"
              fill="#1B1B1B"
            />
          </g>

          {/* Bright */}
          <g
            className={"emoji-bright right-bright"}
            filter="url(#filter6_di_0_20)"
          >
            <circle cx="139" cy="37.5" r="10" fill="#FFFEFA" />
          </g>
        </g>
      </g>
    </svg>
  );
}
