import React from "react";
import { Button, Col } from "antd";
import { UserOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { scholarshipGeneralPerms } from "../utils/constants";

const ScholarshipCard = ({
  id,
  team,
  membersInTeam,
  colorNumber,
  buttonAvailability,
  idUR
}) => {
  const colorArray = [
    "#ff0606",
    "#00ffff",
    "#282149",
    "#5d8544",
    "#d1578a",
    "#8265d5",
    "#f3ad26",
    "#7a92a8",
    "#c8997f",
  ];
  return (
    <Col
      style={{
        background: "white",
        borderRadius: "10px",
        border: "1px solid #ccc",
        boxShadow: "1px 0px 0px 0px rgba(0,0,0,)",
        padding: "10px",
        width: "220px",
        margin: "1%",
      }}
    >
      <div
        style={{
          height: "120px",
          width: "100%",
          background: `linear-gradient(${colorArray[colorNumber % 9]}, white) `,
          borderRadius: "10px",
        }}
      />
      <div style={{ paddingLeft: "10px" }}>
        <h2>{team}</h2>
      </div>
      <div style={{ paddingLeft: "10px" , display: "flex",  justifyContent: "space-between"}}>
        <div >
          <UserOutlined />
          {membersInTeam} integrantes
        </div>
        <div>
        {colorNumber === 0 ? (
          <NavLink to="scholarshipGeneralInfo">
            <Button
              style={{ borderRadius: "5px" , background : (scholarshipGeneralPerms(idUR)) && "#40a9ff",  color:"#ffffff"  }}
              icon={<ArrowRightOutlined />}
              disabled={!(scholarshipGeneralPerms(idUR))}
            />
          </NavLink>
        ) : (
          <NavLink to={`studentScholarshipInfo/${id}`}>
            <Button
              style={{ borderRadius: "5px" , background :(scholarshipGeneralPerms(idUR)|| buttonAvailability) && "#40a9ff", color:"#ffffff"}}
              icon={<ArrowRightOutlined />}
              disabled={!(scholarshipGeneralPerms(idUR)|| buttonAvailability)} 
            />
          </NavLink>
        )}
        </div>
      </div>
    </Col>
  );
};

export default ScholarshipCard;
