import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './styles/eventDetails.css';
import Arrow from '../../resources/left-chevron.png';
import { EditFilled } from '@ant-design/icons';
import server, { endpoints } from '../../utils/server';
import SatisfactionMeter from './satisfactionMeter';

const EventDetails = () => {
    const param = useParams();
    const id = parseInt(param.id);
    const [name, setName] = useState('');
    const [event, setEvent] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [votes, setVotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // Estado para el ordenamiento
    const [active, setActive] = useState(false);
    useEffect(() => {
        getEvent();
        getEventData();
    }, [active]);

    const getEvent = async () => {
        const params = { id_expo_event: id }
        const response = await server(endpoints.getExpoEvent.route, endpoints.getExpoEvent.method, params);

        if (response.error_message) {
            console.error(response.error_message);
        }
        const temp = response.data.find(item => item.id === id);
        setName(temp.name);
    }

    const getEventData = async () => {
        const params = { id_expo_event: id };
        const response = await server(endpoints.getExpoEventData.route, endpoints.getExpoEventData.method, params);

        if (response.error_message) {
            console.error(response.error_message);
        }

        setEvent(response.data.expoEventData);
        setVotes(response.data.expoEventVotes);
        setFilteredData(response.data.expoEventData);
        setLoading(false);
    };

    const updateExpoEvent = async (id, value) => {
        const params = { id_expo_event: id };
        const body = { name: value };
        const response = await server(endpoints.updateExpoEvent.route, endpoints.updateExpoEvent.method, params, body);

        if (response.error_message) {
            console.error(response.error_message);
        }

    };

    const translateType = (type) => {
        switch(type) {
            case 'good':
                return 'Bueno';
            case 'average':
                return 'Medio';
            case 'bad':
                return 'Malo';
            default:
                return type;
        }
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedData = [...filteredData].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredData(sortedData);
    };

    const chartOptions = {
        chart: { type: 'donut' },
        title: {
            text: 'Resultados',
            align: 'center',
            style: { fontSize: '20px', fontWeight: 'bold', color: '#333' }
        },
        labels: ['Buenos', 'Medios', 'Malos'],
        colors: ['#28a745', '#ffc107', '#dc3545'],
        stroke: { width: 2, colors: ['#fff'], lineCap: 'round' },
        plotOptions: {
            pie: {
                donut: {
                    size: '88%',
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'Votos',
                            formatter: () => event.length.toString(),
                        }
                    }
                }
            }
        },
        legend: { show: false },
        dataLabels: { enabled: false },
        responsive: [{ breakpoint: 480, options: { chart: { width: 300 } } }]
    };

    const chartSeries = [ parseInt(votes.good_votes), parseInt(votes.average_votes), parseInt(votes.bad_votes)];

    return (
        <div className="eventDetails-container">
            <div className="eventDetails-Top">
                <div className="eventDetails-Btn-Return" onClick={() => window.history.back()}>
                    <img src={Arrow} alt="Arrow" />
                    <p className="eventDetails-Return">Regresar</p>
                </div>
            </div>
            <div className="eventDetails-content">
            {!loading && (
                <div className="eventDetails-graph">
                    <Chart options={chartOptions} series={chartSeries} type="donut" width="320" />
                    <div className="eventDetails-legend">
                        <h2>Total</h2>
                        <div className="legend-item">
                            <span className="legend-color" style={{ backgroundColor: '#28a745' }}></span>
                            <span>Buenos</span>
                            <span className="legend-value">{votes.good_votes}</span>
                        </div>
                        <div className="legend-item">
                            <span className="legend-color" style={{ backgroundColor: '#ffc107' }}></span>
                            <span>Medios</span>
                            <span className="legend-value">{votes.average_votes}</span>
                        </div>
                        <div className="legend-item">
                            <span className="legend-color" style={{ backgroundColor: '#dc3545' }}></span>
                            <span>Malos</span>
                            <span className="legend-value">{votes.bad_votes}</span>
                        </div>
                    </div>
                    <button className="satisfactometer-button" onClick={() => setActive(true)}>Ir al Satisfactometro</button>
                </div>
            )}
            
                <div className="eventDetails-details">
                    <div className="input-wrapper">
                        <input type="text" value={name} onChange={(e) => {setName(e.target.value); updateExpoEvent(id, e.target.value);}} placeholder="Nombre"/>
                        <span className="input-icon"><EditFilled /></span>
                    </div>
                    <div className="eventDetails-table-container">
                        <table className="eventDetails-table">
                            <thead>
                                <tr>
                                    <th>
                                        ID
                                        <button onClick={() => handleSort('id_answering')} className="filter-button">⇅</button>
                                    </th>
                                    <th>
                                        Tipo Usuario
                                        <button onClick={() => handleSort('voter_rol')} className="filter-button">⇅</button>
                                    </th>
                                    <th>
                                        Voto
                                        <button onClick={() => handleSort('type')} className="filter-button">⇅</button>
                                    </th>
                                    <th>
                                        Fecha
                                        <button onClick={() => handleSort('date')} className="filter-button">⇅</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.id_answering}</td>
                                        <td>{item.voter_rol}</td>
                                        <td>
                                            <span className={`vote-dot ${item.type.toLowerCase()}`} />
                                            {translateType(item.type)}
                                        </td>
                                        <td>{item.date.slice(0, 10).split('-').reverse().join('/')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {active===true ? <SatisfactionMeter idExpoEvent={id} setActive={setActive} /> : null}
        </div>
    );
};

export default EventDetails;
