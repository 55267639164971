import React, { useState, useEffect } from "react";

import "./MembersDB.css";
import server, { endpoints } from "../../utils/server";

import { Col, Row, message } from "antd";
import { UserOutlined } from "@ant-design/icons";

const MemebersDB = ({
  id_cycle,
  id_evaluation,
  allTeamsSelected,
  onTeamSelectedChange,
}) => {
  const [evaluations, setEvaluations] = useState();
  const [data, setData] = useState(0);
  const [teamSelected, setTeamSelected] = useState(0);
  useEffect(() => {
    getEvaluation();
  }, []);

  useEffect(() => {
    handleEvaluationChange();
  }, [allTeamsSelected]);

  useEffect(() => {
    getEvaluationsUsersThatAnswered();
  }, [id_evaluation, teamSelected]);

  const getEvaluation = async () => {
    const params = {
      id_cycle,
    };
    const res = await server(
      endpoints.getEvaluation.route,
      endpoints.getEvaluation.method,
      params
    );
    const evaluations = res.data;

    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    setEvaluations(evaluations);
  };
  const getEvaluationsUsersThatAnswered = async () => {
    const params = {
      id_cycle,
      id_team: teamSelected,
      id_evaluation,
    };
    const res = await server(
      endpoints.getEvaluationsUsersThatAnswered.route,
      endpoints.getEvaluationsUsersThatAnswered.method,
      params
    );
    const teamAnswer = res.data;

    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }

    setData(teamAnswer);
  };
  const handleTeamChange = (id) => {
    onTeamSelectedChange(id);
  };
  const handleEvaluationChange = () => {
    allTeamsSelected.map((item) => {
      if (item.id === teamSelected) {
        onTeamSelectedChange(item);
      }
    });
  };
  return (
    <div /*className="Members-DB-topDiv"*/>
      <Row className="Members-DB-topRow">
        <Col span={20} className="Members-DB-Col">
          <Row className="Members-DB-Info">
            <Col span={3} className="Evaluation-names" style={{}}>
              {allTeamsSelected.map((t) => {
                return (
                  <Row
                    key={t.id}
                    className="Evaluation-name"
                    style={{
                      color: id_evaluation === t.id && "#1892ff",
                      background: id_evaluation === t.id && "#FFFFFF",
                    }}
                    onClick={() => {
                      setTeamSelected(t.id);
                      handleTeamChange(t);
                    }}
                    justify="center"
                  >
                    <div className="Evaluation-names-text">
                      {t.name}
                      {id_evaluation === t.id && <div></div>}
                    </div>
                  </Row>
                );
              })}
            </Col>
            <Col span={19} className="Members">
              <Row className="filters">
                <Col span={6} offset={2}>
                  <Row justify="center"> ID</Row>
                </Col>
                <Col span={8}>
                  <Row> Nombre</Row>
                </Col>
                <Col span={8}>
                  <Row justify="center"> Estado de respuesta</Row>
                </Col>
              </Row>

              {data != 0 &&
                data.usersThatAnswered.map((user, index) => {
                  return (
                    <Row
                      className="Member-of-Team"
                      key={user.id}
                      align="middle"
                    >
                      <Col span={3}>
                        <Row
                          className="Foto-de-perfil-border"
                          justify="center"
                          align="middle"
                        >
                          <UserOutlined className="Foto-de-perfil" />
                        </Row>
                      </Col>
                      <Col span={4}>{user.id}</Col>
                      <Col span={12} className="member-name">
                        {user.name}
                      </Col>
                      <Col
                        span={5}
                        style={{
                          color: user.has_answered ? "#1892ff" : "#f91f1f",
                        }}
                      >
                        {user.has_answered ? "Constestado" : "Pendiente"}
                      </Col>
                    </Row>
                  );
                })}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default MemebersDB;
